.change-password-form label {
  color: #217dc3;
  text-transform: uppercase;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-family: Roboto-bold;
}
.change-password-form .form-control {
  border-color: #217dc3;
}
.change-password-form .input-group-prepend > span {
  border-color: #217dc3;
}
.change-password-form .primary-btn {
  background-color: #2580eb;
  border-color: #4b78af;
  text-transform: uppercase;
  font-size: 0.6rem;
  color: #fff;
  font-family: Roboto-bold;
  letter-spacing: 1px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.change-password-form .primary-btn:hover {
  opacity: 0.9;
}
.roboto-italic {
  font-family: Roboto-italic;
}
.change-password-form .otp-box-2 input {
  background: #ffffff;
  border: 1px solid #217dc3;
  float: left;
  margin: 3px;
  padding: 0.25rem 0.5rem;
}

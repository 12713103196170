.video-container {
  flex: 1;
  display: flex;
  height: calc(100% - 100px);
}

.header {
  height: 65px;
  box-shadow: 0px 1px 2px #00000029;
  display: flex;
  padding: 0 20px;
  align-items: center;
  /* justify-content: center; */
  font: normal normal 500 25px/38px Poppins;
  letter-spacing: 3.75px;
  color: #707070;
}

.info {
  width: 35%;
  margin-top: 0px;
}

.video {
  width: 70%;
  flex: 1;
  position: relative;
  // margin: 20px;
  border-collapse: separate;
  overflow: hidden;
  /* perspective: 1px; */
  border-radius: 15px;
}

.chime-video-container {
  display: flex;
  height: 90%;
}
.local-video{
  display: block !important;
  position:relative;
}

.flipIcon{
    color: black;
    &:hover{
        color: #fff;
    }
}
.aws-chime-toolbar{
    height: 3.5rem !important;
}
.chat-notification{
    height: 8px;
    width: 8px;
    right: 3px;
    background: red;
    border-radius: 50%;
    position: absolute;
}
.remote-tile-custom{
    position: relative;
    display: grid;
    height: 100%;
    width: 100%;
    overflow: auto;
    .ch-remote-video--2,.ch-remote-video--3{
    width: 100%;
    position: initial;
    }
}
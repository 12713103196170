/* STRUCTURE */

.wrapper-login {
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
  // width: calc(100% - 40px); - 148px
  height: calc(100%-95px);
  background-image: url("../assets/images/agent-background.jpg");
  padding: 20px;
  background-size: cover;
}

#formContent {
  padding: 20px;
  text-align: center;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

.formPaper {
  width: 500px;
  max-width: 100%;
  height: 65px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c5c5c5 !important;
  border-radius: 8px !important;
  margin: 20px 0;
  align-items: center;
  opacity: 1;
  display: flex;
}

.iconContainer {
  width: 20%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
}

.headerText {
  width: 500px;
  max-width: 100%;
  text-align: left;
  font: normal normal 300 40px/48px Roboto;
  letter-spacing: 6px;
  margin: 0;
  color: #ffffff;
  opacity: 1;
}

.forgotHeader {
  text-align: left;
  font: italic normal 300 40px/48px Roboto;
  letter-spacing: 0.4px;
  margin: 0;
  color: #ffffff;
  opacity: 1;
}

.loginButton {
  width: 500px;
  max-width: 100%;
  height: 65px;
  border-radius: 8px !important;
  cursor: pointer !important;
  color: #fff !important;
  text-transform: none !important;
  text-align: center;
  font: normal normal 300 20px/24px Roboto;
  font-size: 20px !important;
}

.sendButton {
  width: 115px;
  height: 55px;
  margin-right: 5px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  color: #fff !important;
  text-transform: none !important;
  text-align: center;
  /* font: normal normal 300 20px/24px Roboto; */
  font-size: 20px !important;
  opacity: 0.7;
}

.textField.Mui-error {
  color: red !important;
}

.divider {
  width: 2px !important;
}

.textField {
  width: 80%;
  text-align: left;
  font: normal normal 300 20px/24px Roboto;
  margin-left: 20px !important;
  color: #ababab !important;
  opacity: 1;
  .MuiInputBase-input {
    letter-spacing: 3px !important;
  }
}

.forgotButton {
  font-size: 18px !important;
  text-align: left;
  font: italic normal 600 18px/22px Roboto;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  opacity: 1;
  margin-bottom: 25px !important;
}

.headerContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  opacity: 1;
  display: flex;
  border: 0px solid #ababab;
  height: 53px;
}

.countryName {
  width: 30%;
  padding: 18px;
  text-align: right;
  align-self: center;
  //border-right: 1px solid #ababab;
  /* text-align: left; */
  font: normal normal 300 25px/30px Roboto;
  letter-spacing: 3.75px;
  color: #ababab;
  opacity: 1;
}

.compoanyName {
  text-align: left;
  padding: 18px;
  align-self: center;
  width: 70%;
  /* border: 1px solid #ABABAB; */
  font: normal normal 300 25px/30px Roboto;
  letter-spacing: 3.75px;
  color: #ababab;
  opacity: 1;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  //padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

// input[type="text"],
// input[type="password"] {
//   background-color: #f6f6f6;
//   border: none;
//   color: #0d0d0d;
//   //  padding: 15px 32px;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
//   font-size: 16px;
//   margin: 5px;
//   width: 85%;
//   border: 1px solid grey;
//   -webkit-transition: all 0.5s ease-in-out;
//   -moz-transition: all 0.5s ease-in-out;
//   -ms-transition: all 0.5s ease-in-out;
//   -o-transition: all 0.5s ease-in-out;
//   transition: all 0.5s ease-in-out;
//   -webkit-border-radius: 5px 5px 5px 5px;
//   border-radius: 5px 5px 5px 5px;
// }

// input[type="text"]:focus {
//   background-color: #fff;
//   border-bottom: 2px solid #5fbae9;
// }

// input[type="text"]:placeholder {
//   color: #cccccc;
// }
// input[type="password"]:focus {
//   background-color: #fff;
//   border-bottom: 2px solid #5fbae9;
// }

// input[type="password"]:placeholder {
//   color: #cccccc;
// }

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
// .fadeInDown {
//   -webkit-animation-name: fadeInDown;
//   animation-name: fadeInDown;
//   -webkit-animation-duration: 1s;
//   animation-duration: 1s;
//   -webkit-animation-fill-mode: both;
//   animation-fill-mode: both;
// }

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 20%;
}

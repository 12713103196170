.formTextPaper {
  width: 500px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c5c5c5 !important;
  border-radius: 8px !important;
  margin: 20px 0;
  align-items: center;
  opacity: 1;
  display: flex;
}

.textField {
  width: 80%;
  text-align: left;
  font: normal normal 300 20px/24px Roboto;
  margin-left: 20px !important;
  color: black !important;
  opacity: 1;
  .MuiInputBase-input {
    letter-spacing: 3px !important;
  }
}

#modalContainer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #60c1f1;
  opacity: 0.9;
  width: 500px;
}

.modalText {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0.25px;
}

.modalBackground {
  background: "#4ED960";
  color: "white";
}

.cardMain {
  display: inline-block;
  margin-top: 25px;
}

.doneButton {
  background: #4ed960;
  color: white;
  width: 100%;
  margin-top: 20px;
}
.modalColor {
  color: #4ed960;
}

// .MuiButton-label {
//   color: white;
// }

.mismatchError {
  text-align: left;
  color: red;
}

#successIcon {
  height: 76px;
  width: 67px;
}

.upload-form {
    display: block;
    margin-bottom: 1.3rem;

    label {
        display: block;
        margin-bottom: 0.2rem;
        font-family: 'poppins-Medium';
    }
    .astric{
        color: red;
    }

    input, input[type='date'], select {
        border: none;
        border-bottom: 1px solid lightgray;
        background: whitesmoke;
        width: 100%;
        font-size: 14px;
        padding: 3px 10px;
        transition: width .2s ease, border-bottom .2s ease;
        font-family: 'poppins-Medium';
        &::placeholder {
            color: darkgrey;
            opacity: 1;
        }
        &:hover {
            box-shadow: 0 0 5pt 0.5pt #D3D3D3;
        }

        &:focus {
            border-bottom: 2px solid black;
            box-shadow: 0 0 5pt 2pt #D3D3D3;
        }
    }
}
.pageUpload-title{
    font-family: 'poppins-Medium';
    font-weight: 500;
}

.link-btn {
    cursor: pointer;
    color: #217dc3;
    border: none;
    background-color: transparent;

    &:hover {
        text-decoration: underline;
        box-shadow: 0 0 5pt 0.5pt #D3D3D3;
        border-radius: 5px;
        padding:0 5px;
    }
}

.form-error {
    margin-bottom: 0px;
    padding-left: 5px;
    font-size: 11px;
    color: red;
    position: absolute;
    font-family: 'poppins-Medium';
}
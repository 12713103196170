.custom-dropdown {
    position: relative;
    width: auto;
    font-family: Arial, sans-serif;
  }
  
  .dropdown-header {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
  }
  
  .arrow {
    margin-left: 10px;
  }
  
  .arrow.up {
    transform: rotate(180deg);
  }
  
  .options {
    position: absolute;
    top: 6px;
    left: 50%;
    width: auto;
    border-radius: 5px;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);;
    right: auto;
    transform: translateX(-50%);
  }
  
  .option {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 0.35rem 1.5rem;
    font-weight: bold;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    border: 0;
    font-size: 12px;
    text-decoration: none !important;
  }
  .option-disabled{
    display: block;
    width: 100%;
    padding: 0.35rem 1.5rem;
    font-weight: bold;
    color: #212529;
    text-align: left;
    white-space: nowrap;
    border: 0;
    font-size: 12px;
    text-decoration: none !important;
  }
  
  .option:hover {
    background-color: #12b4fb;
    color: #fff !important;
  }
  
  .custom-dropdown-toggle {
    background-color: transparent;
    color: #212529;
    padding: 5px;
    border-radius: 37%;
    cursor: pointer;
  }
  
  .custom-dropdown-toggle:hover {
    background-color: #dfdfdf;
  
  }
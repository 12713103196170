.feedback-header {
  font-size: 12px;
  color: red;
  font-weight: 500;
  text-align: left !important;
}

.feedback-counter {
  font-size: 12px;
  font-weight: 500;
  float: right;
  margin-top: 5px;
  color: black;
}

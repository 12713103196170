.app {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
}

.agent-dashboard-container {
  height: 100%;
}

.caseContainer {
  height: 18em;
  /* overflow-y: auto; */
  overflow: hidden;
  overflow-y: auto;
}

.pagination-border {
  border-bottom: none !important;
}

.container-p {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  background: #eee;
  // height: 100%;
  padding: 30px;
}

.heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-container {
  display: flex;
}

.left-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
}

.right-container {
  display: flex;
  flex: 1;
  padding: 20px;
  flex-direction: column;
}

// input {
//   width: 100%;
//   height: 30px;
//   padding: 12px 20px;
//   margin: 8px 0;
//   box-sizing: border-box;
// }

.video-container {
  flex: 1;
  display: flex;
  height: 100%;
}

.btn-green {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
}

/* .info-container {
    flex: 1;
  } */

.heading-text {
  font-family: "Times New Roman", Times, serif;
  color: #5eb9ff;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  margin-top: 20px;
  margin-left: 350px;
  margin-bottom: 20px;
}

@font-face {
    font-family: OpenSans-SemiBold;
    src: url("../../../font/OpenSans-SemiBold.ttf") format("truetype");
  }
  .card-header.bg-light-blue {
    /* background-color: ; */
  }
  .fnt-open-sans-s-bld {
    font-family: OpenSans-SemiBold;
  }
  .txt-666 {
    color: #666;
  }
  .bg-light-gray {
    background-color: #f9f9f9;
  }
  // .red .location-icon g {
  //   stroke: #ff0000;
  // }
  // .red .location-icon circle,
  // .red .location-icon path,
  // .red .audio-video-icon path,
  // .red .audio-video g#Path_2537,
  // .red .network-icon path {
  //   fill: #ff0000;
  // }
  // .red .fnt-sml {
  //   color: #ff0000 !important;
  // }
  .bg-light-danger {
    background-color: #f98787;
  }
  .allow {
    padding: 0px 5px;
    font-size: 0.7rem;
  }
  
  .recheck-btn {
    margin-left: 10px;
    background-color: red;
    color: #fff;
    font-size: 0.7rem;
    padding: 0px 5px;
  }
  
  .recheck-btn:hover {
    opacity: 0.5;
    color: #fff;
  }
  
  .next-button {
    width: 100px;
    margin-top: 100px;
    margin-bottom: 20px;
  }
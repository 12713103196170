
.mainContainer {
    width: 500px;
    max-width: 100%;
    display: flex;
    margin: auto;
}
.subContainer {
    width: 100%;
    /* padding-right: 15px; */
    padding: 0 15px;
    /* margin-right: auto; */
    margin-top: 1.5em;
}

.errorTxt {
    margin-top: 0.5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ef4646;
    font-size: 1rem;
}

.panHeader {
    margin: 0 0 .5em 0;
    font-family: inherit;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 1.5rem;
    line-height: 1.2;
    color: #5EB9FF;
}

.infoTxtPan {
    letter-spacing: 1px;
    font-size: 0.8rem;
    color: #818181;
    margin: 0.7em 0;
}

.inputContainer {
    margin-bottom: .5rem!important;
}

.imgPan {
    border-radius: .25rem!important;
    max-width: 100%;
}

.successTxt {
    margin-top: .5rem!important;
    display: flex;
    align-items: center;
    color: #21A947;
    font-size: .7rem;
}

.box {
    padding: 1.5rem!important;
    margin-bottom: .5rem!important;    
    width: 100%;
    border-radius: .25rem!important;
    border: 2px dashed #dee2e6!important;
}
.call-sec {
  background-color: #dbdbdb2e;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  padding: 10px 20px;
  position: relative;
}

.mt-150 {
  margin-top: 150px;
}
.user-blue-circle-big {
  width: 40px;
  height: 40px;
}
.call-sec .user-blue-circle-big {
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: 500;
}
@keyframes example {
  0% {
    background-color: red;
    top: -120px;
  }
  25% {
    background-color: yellow;
    top: -60px;
  }
  50% {
    background-color: #217dc3;
    top: -90px;
  }
  100% {
    background-color: red;
    top: -30px;
  }
}

.livecase-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #707070;
  width: 500px;
  border-radius: 29px;
  background-color: white;
}

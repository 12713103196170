.scheduleContainer {
    background: #0F77C7 0% 0% no-repeat padding-box;
    height: 100%;
    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
}

.scheduleHeader {
    font: normal normal 500 25px/30px Poppins;
    letter-spacing: 2.5px;
    color: #FFFFFF;
    margin: .5em 0;
}

.scheduleTxt {
    font: normal normal 300 20px/25px Poppins;
    letter-spacing: 2.5px;
    color: #FFFFFF;
}

.scheduleButton {
    justify-content: flex-end;
    margin-top: 2em;
}
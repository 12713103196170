.mainCont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  display: flex;
  text-align: left;
  margin-top: 1.5em;
  padding: 0 15px;
}

.aadhaar-heading {
  font-family: inherit;
  color: #5eb9ff;
  margin-top: 25px;
}

.loaderTxt {
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: inherit;
  font-weight: 500;
  margin-top: 1rem !important;
  font-size: 1.25rem;
  margin-bottom: 1rem !important;
  letter-spacing: 1px;
  color: #666666;
  line-height: 1.2;
}

.description {
  font-family: inherit;
  color: #818181;
}

.uploadDocContainer {
  width: 200px;
  height: 200px;
  cursor: pointer;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border: 1px dashed #6bafe4;
}

.uploadIcon {
  font-size: 5rem;
}

.uploadText {
  font: normal normal normal 20px/30px Poppins;
  color: #6bafe4;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  // display: inline-block;
  cursor: pointer;
}

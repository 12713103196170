.root1 {
    border-radius: 25px !important;
    margin: 0px 20px;
    height: 40px;
    display: flex;
    padding: 0pc 10px;
    align-items: center;
}

.input1 {
    height: 40px;
    padding-left: 10px;
}

.verificationInputPaper {
  width: 100%;
  max-width: 100%;
  height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ababab !important;
  border-radius: 35px !important;
  margin: 5px 0;
  align-items: center;
  opacity: 1;
  display: flex;
}

.verificationInputField {
  flex: 1;
  overflow: hidden;
  text-align: left;
  font: normal normal normal 15px/18px Roboto;
  margin-left: 5px !important;
  color: #2fa5df !important;
  opacity: 1;
  .MuiInputBase-input {
    letter-spacing: 0.75px !important;
  }
}

.verificationPreInput {
  font: normal normal normal 15px/18px Roboto;
  letter-spacing: 0.75px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  margin:0 5px;
}

.verificationIconContainer {
  display: flex;
}

.verificationDivider {
  width: 2px !important;
}

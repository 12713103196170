/* Simple modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Dark background with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* Modal content styling */
  .modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
  }
  
  /* Image styling */
  .image-preview {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }
  
  /* Close button styling */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
  }
  .close-btn:hover {
    color: red;
  }
  
@font-face {
  font-family: poppins-regular;
  src: url("./font/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: poppins-bold;
  src: url("./font/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: poppins-Medium;
  src: url("./font/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: Roboto-regular;
  src: url("./font/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Roboto-bold;
  src: url("./font/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Roboto-Condensed;
  src: url("./font/Roboto-Condensed.ttf") format("truetype");
}

@font-face {
  font-family: Roboto-CondensedItalic;
  src: url("./font/Roboto-CondensedItalic.ttf") format("truetype");
}

@font-face {
  font-family: Roboto-BoldCondensed;
  src: url("./font/Roboto-BoldCondensed.ttf") format("truetype");
}

@font-face {
  font-family: Segoi-ui;
  src: url("./font/segoeui.ttf") format("truetype");
}

@font-face {
  font-family: "robotoregular";
  src: url("./font/roboto-regular-webfont.woff2") format("woff2"),
    url("./font/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  height: 100vh;
  min-height: -webkit-fill-available;
  // overflow-x: hidden;
}

body {
  font-family: "robotoregular" !important;
  font-weight: normal;
  // background-color: #fcfcfc;
}

.main-body {
  width: 100%;
  max-width: 460px;
}

.main-body h4 {
  font-size: 1rem;
  letter-spacing: 0.1875rem;
  font-family: poppins-regular;
}

.main-body h5 {
  font-size: 0.8rem;
  letter-spacing: 0.15625rem;
  font-family: poppins-regular;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

button:focus {
  outline: none;
}

.light-blue-customer-template header {
  background-color: #5eb9ff;
  height: 50px;
}

.light-blue-customer-template .bg-light {
  background-color: #fcfcfc !important;
}

.dashed-brdr {
  border-style: dashed !important;
}

.brdr-light-blue {
  border-color: #6bafe4 !important;
}

.radius-50 {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.txt-light-blue {
  color: #5eb9ff;
}

.txt-light-black {
  color: #666666;
}

.txt-81 {
  color: #818181;
}

.ltr-spc-0-5 {
  letter-spacing: 0.5px;
}

.pointer {
  cursor: pointer;
}

.white-box {
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #c5c5c5;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  min-height: 65px;
  display: flex;
  align-items: center;
}

.white-box.active {
  box-shadow: 0px 1px 2px #00000029;
  border: 3px solid #5eb9ff;
}

.white-box>.left {
  width: 80%;
}

.white-box>.right {
  width: 20%;
}

.fnt-sml {
  font-size: 0.7rem;
}

.aadhaar-icon svg,
.fingerprint-icon svg {
  width: 40px;
}

.tick-icon svg {
  width: 30px;
}

.trash-icon img {
  width: 18px;
}

.fingerprint-icon svg {
  height: 37px;
}

.xml-icon svg {
  width: 30px;
}

.icon-green2 path {
  fill: #11ac20;
}

.white-box.active .fingerprint-icon rect {
  fill: #5eb9ff;
}

.white-box.active .icon-light-blue path {
  fill: #5eb9ff;
}

.white-box.active .icon-green path {
  fill: #24df24;
}

.white-box .aadhaar-icon path {
  fill: #e8e8e8;
}

.p-5-15 {
  padding: 5px 15px;
}

.fingerprint-icon {
  margin-top: 5px;
}

.equal-box {
  min-height: 180px;
}

.flex-d-column {
  flex-direction: column;
}

.camera-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  padding-left: 30px;
  padding-right: 30px;
}

.camera-screen .header {
  position: absolute;
  width: 100%;
  top: 0;
}

.camera-view {
  min-height: 250px;
  background-color: #fff;
  top: 50px;
  position: relative;
  width: 100%;
}

.camera-screen .footer {
  position: fixed;
  bottom: 20px;
  width: 100%;
  left: 0;
  right: 0;
  height: 70px;
  padding-left: 30px;
  padding-right: 30px;
}

.w-30 {
  width: 30px !important;
}

.w-30-p {
  width: 30% !important;
}

.h-30 {
  height: 30px;
}

.h-90 {
  height: 90%;
}

.clientTxt {
  overflow: auto;
  max-height: 27em;
}

.brdr-ab {
  border-color: #ababab !important;
}

.input-with-label-group {
  padding: 3px 3px 3px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-with-label-group .left {
  display: flex;
  align-items: center;
  width: 90%;
}

.input-with-label-group .left>label {
  display: inline-block;
  font-size: 0.7em;
  color: #707070;
  width: auto;
  margin-bottom: 0;
  margin-right: 3px;
}

.input-with-label-group .form-control {
  color: #2fa5df;
  font-size: 0.7em;
  width: 70%;
  padding: 0;
  background-color: transparent;
  border: 0;
  height: 20px;
}

.input-with-label-group .form-control:focus {
  box-shadow: none;
}

// .custom-checkbox input[type="checkbox"] {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   position: absolute;
//   left: -2px;
//   border-color: white;
// }
.custom-checkbox label {
  border: 1px solid #21a947;
  padding: 5px 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  cursor: pointer;
  width: 23px;
  display: block;
  height: 23px;
  margin-bottom: 0;
  background-image: url(./assets/icons/tick.png);
  background-repeat: no-repeat;
  margin-bottom: 0;
  background-position: left 2px top 5px;
  background-size: 70%;
}

// .custom-checkbox input[type="checkbox"]:checked + label {
//   background-color: #21a947;
//   border: 1px solid #21a947;
//   background-image: url(./assets/icons/tick-white.png);
// }
.custom-checkbox label.sml {
  width: 17px;
  height: 17px;
  background-position: left 2px top 4px;
}

.tick-icon-green-sml {
  background-image: url(./assets/icons/tick-white.png);
  background-position: left 3px top 5px;
  background-size: 50%;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  background-color: #21a947;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
}

.txt-green {
  color: #21a947;
}

.btn-custom {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 0.8rem;
}

.btn-light-blue {
  background-color: #5eb9ff !important;
  color: #fff !important;
}

.w-100-p {
  width: 100px;
}

.brdr-c5 {
  border-color: #c5c5c5 !important;
}

.bg-c5 {
  background-color: #c5c5c5 !important;
}

.gallery-thumb-box .col-md-4 {
  margin-bottom: 10px;
}

.brdr-green {
  border-color: #11ac20;
}

.brdr-light-blue {
  border-color: #6bafe4;
}

.txt-light-gray {
  color: #c5c5c5;
}

.btn-fixed-btm {
  position: fixed;
  bottom: 0;
  right: 30px;
  left: 30px;
}

.btn-fixed-btm .col-half {
  width: 50%;
}

.h-100vh {
  height: 100vh;
}

.bg-c5-opacity {
  background-color: rgba(197, 197, 197, 0.27);
}

.brdr-c5-opacity {
  border-color: rgba(197, 197, 197, 0.27);
}

.mt-100 {
  margin-top: 100px;
}

.ltr-spc-12 {
  letter-spacing: 12px;
}

.otp-filled input {
  background-color: #5eb9ff;
  font-weight: bold;
  color: #fff;
}

.fnt-bld {
  font-weight: bold;
}

#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  // background-image: linear-gradient(
  //   to left,
  //   black 50%,
  //   rgba(255, 255, 255, 0) 0%
  // );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 200px;
}

.otp-box input {
  float: left;
  margin: 5px;
}

.otp-box input:focus {
  box-shadow: none;
  border: none;
}

.circle-sml {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  width: 25px;
  height: 25px;
}

.bg-light-blue {
  background-color: #5eb9ff;
}

.fnt-1rem {
  font-size: 1rem;
}

.bg-0f7 {
  background-color: #0f77c7;
}

.right-sec .inner {
  position: relative;
  min-height: 500px;
}

.call-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  object-fit: cover;
}

.rounded-15 {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

.right-sec .inner .second-prsn-box {
  position: absolute;
  top: 30px;
  right: 30px;
}

.right-sec .inner .bottom-sec {
  position: absolute;
  left: 0;
  bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-sec .inner .bottom-sec .name {
  position: absolute;
  bottom: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  padding: 2px 20px 5px;
  left: 39.8%;
}

.resize-icon-white {
  cursor: pointer;
  //// background-image: url(./assets/icons/resize-icon.png);
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.screen-rotate-icon-white {
  cursor: pointer;
  //// background-image: url(./assets/icons/screen-rotate-icon.svg);
  background-position: left -5px top -5px;
  background-repeat: no-repeat;
  background-size: 125%;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.add-icon-blue {
  cursor: pointer;
  //// background-image: url(./assets/icons/add-btn-icon.svg);
  background-position: left -5px top -5px;
  background-repeat: no-repeat;
  background-size: 125%;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.camera-icon-black {
  cursor: pointer;
  //// background-image: url(./assets/icons/camera-icon-sml.png);
  background-size: 40%;
  background-position: left 12px top 13px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.speaker-icon-black {
  cursor: pointer;
  //// background-image: url(./assets/icons/speaper-icon.png);
  background-size: 40%;
  background-position: left 12px top 13px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.call-icon-red {
  cursor: pointer;
  //  background: url("./assets/icons/call-icon-sml.png") no-repeat left 4px top 6px,
  //  linear-gradient(139deg, #de1a7c 0%, #ff0765 100%);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.crop-icon-blue {
  cursor: pointer;
  // background: url("./assets/icons/crop-icon-sml.png") no-repeat left 4px top 6px,
  //  linear-gradient(139deg, #de1ade 0%, #1e07ff 100%);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.eye-icon-gray {
  cursor: pointer;
  // background: url("./assets/icons/eye-icon-gray-sml.png") no-repeat left;
  width: 22px;
  height: 12px;
  display: inline-block;
}

.positioned-to-parent {
  position: absolute;
  right: 5px;
  top: 6px;
}

.right-sec .inner .bottom-sec .code {
  position: absolute;
  bottom: -70px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50px;
  padding: 7px 20px 7px;
  height: 50px;
}

.fnt-1-3 {
  font-size: 1.3rem;
}

.fnt-1-5 {
  font-size: 1.5rem;
}

.right-sec .inner .bottom-sec .code .col:first-child {
  border-right: 2px solid #5eb9ff;
}

.bg-dark-blue {
  background-color: #0f77c7;
}

.light-input {
  border: 1px solid #fff;
  padding: 8px 15px;
  border: 1px solid #d6d6d6;
  background-color: transparent;
  font-size: 0.8rem;
  color: #fff;
  position: relative;
  z-index: 1;
}

.light-input::placeholder {
  color: #fff;
}

.light-input:focus {
  background-color: transparent;
}

.calander-icon-white {
  cursor: pointer;
  //// background-image: url(./assets/icons/calendar.svg);
  background-position: left 5px top 10px;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.time-icon-white {
  cursor: pointer;
  //// background-image: url(./assets/icons/time-icon.svg);
  //  background-position: left 5px top 9px;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.icon-position-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.btn-dark-blue {
  background-color: #043860;
  color: #fff;
}

.btn-dark-blue:hover {
  opacity: 0.8;
}

.rounded-0 {
  border-radius: 0;
}

.carousel-indicators {
  bottom: 0;
}

.carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  margin-right: 10px;
  margin-left: 10px;
}

.carousel-indicators li.active:before {
  content: "";
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: #bb24df;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.btn-purple {
  background-color: #6613e2;
  color: #fff;
}

.custom-checkbox-normal {
  position: relative;
}

// .custom-checkbox-normal input[type="checkbox"] {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   position: absolute;
//   left: -2px;
//   border-color: #c5c5c5;
// }
// .custom-checkbox-normal input[type="checkbox"]:before {
//   content: "";
//   border: 1px solid #c5c5c5;
//   border-radius: 50px;
//   -webkit-border-radius: 50px;
//   -moz-border-radius: 50px;
//   cursor: pointer;
//   width: 18px;
//   display: inline-block;
//   height: 18px;
//   margin-bottom: 0;
//   top: 5px;
//   position: relative;
//   margin-bottom: 0;
// }
// .custom-checkbox-normal input[type="checkbox"]:focus {
//   outline: none;
// }
// .custom-checkbox-normal input[type="checkbox"]:checked:before {
//   outline: none;
// }
// .custom-checkbox-normal input[type="checkbox"]:checked:after {
//   content: "";
//   background-color: #5eb9ff;
//   border-radius: 50px;
//   -webkit-border-radius: 50px;
//   -moz-border-radius: 50px;
//   cursor: pointer;
//   width: 8px;
//   display: inline-block;
//   height: 8px;
//   margin-bottom: 0;
//   top: 0px;
//   left: -13px;
//   position: relative;
//   margin-bottom: 0;
// }
.custom-checkbox-normal label {
  margin-left: 25px;
  margin-bottom: 0;
}

.popup-container {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container .inner {
  background-color: #fff;
  width: 100%;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agent-container {
  max-width: 1260px;
}

.light-blue-template header {
  box-shadow: 0px 1px 2px #00000029;
}

.left-gray-box {
  min-height: 500px;
}

.left-gray-box .inner {
  padding: 15px;
}

.ltr-spc-1 {
  letter-spacing: 1px;
}

.btn-custom:hover {
  opacity: 0.9;
}

// .dashed-brdr {
//   border-style: dashed !important;
// }
.brdr-blue {
  border-color: #0071a9 !important;
}

h6.dashed-title {
  border-bottom: 1px dashed #707070;
  padding-bottom: 10px;
}

// .radius-50 {
//   border-radius: 50px;
//   -webkit-border-radius: 50px;
//   -moz-border-radius: 50px;
// }
.light-blue-template .bg-primary {
  background-color: #2fa5df !important;
}

.light-blue-template .btn-light-black {
  background-color: #707070;
  border: 1px solid #707070;
  color: #fff;
}

.light-blue-template .bg-light {
  background-color: #f5f5f5 !important;
}

.border-2 {
  border-width: 2px !important;
}

.brdr-d4 {
  border-color: #d4d4d4 !important;
}

.txt-blue {
  color: #0071a9;
}

.txt-light-blue {
  color: #2fa5df;
}

.bg-f9 {
  background-color: #f9f9f9 !important;
}

.brdr-ab {
  border-color: #ababab !important;
}

.input-with-label-group {
  padding: 3px 3px 3px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-with-label-group .left {
  display: flex;
  align-items: center;
  width: 90%;
}

.input-with-label-group .left>label {
  display: inline-block;
  font-size: 0.7em;
  color: #707070;
  width: auto;
  margin-bottom: 0;
  margin-right: 3px;
}

.input-with-label-group .form-control {
  color: #2fa5df;
  font-size: 0.7em;
  width: 70%;
  padding: 0;
  background-color: transparent;
  border: 0;
  height: 20px;
}

.input-with-label-group .form-control:focus {
  box-shadow: none;
}

// .custom-checkbox input[type="checkbox"],
// .custom-radio input[type="radio"] {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   position: absolute;
//   left: -2px;
//   border-color: white;
// }
.custom-checkbox label,
.custom-radio label {
  border: 1px solid #21a947;
  padding: 5px 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  cursor: pointer;
  width: 27px;
  display: block;
  height: 27px;
  margin-bottom: 0;
  // background-image: url(./assets/icons/tick.svg);
  background-repeat: no-repeat;
  margin-bottom: 0;
  background-position: left 6px top 8px;
  background-size: 50%;
}

.custom-checkbox label.sml,
.custom-radio label.sml {
  width: 15px;
  height: 15px;
  padding: 0;
  background-position: left 3px top 4px;
}

.custom-checkbox label.lrg,
.custom-radio label.lrg {
  width: 50px;
  height: 50px;
  padding: 0;
  background-position: left 12px top 15px;
  background-color: #fff;
}

.custom-checkbox input[type="checkbox"]:checked+label,
.custom-radio input[type="radio"]:checked+label {
  background-color: #21a947;
  border: 1px solid #21a947;
  background-image: url(./assets/icons/tick-white.png);
}

.custom-radio label.cancel-icon {
  border: 1px solid #ee135d;
  background-image: url(./assets/icons/cancel.svg);
  background-size: 40%;
  background-position: left 8px top 8px;
}

.custom-radio input[type="radio"]:checked+label.cancel-icon {
  background-color: #ee135d;
  border: 1px solid #ee135d;
  background-image: url(./assets/icons/cancel-white.svg);
}

.tick-icon-green-sml {
  background-image: url(./assets/icons/tick-white.png);
  background-position: left 3px top 5px;
  background-size: 50%;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  background-color: #21a947;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
}

.txt-green {
  color: #21a947;
}

.fnt-sml {
  font-size: 0.7rem;
}

.fnt-sml-x {
  font-size: 0.6rem;
}

// .right-sec .inner {
//   position: relative;
//   min-height: 400px;
// }
.rounded-15 {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

.right-sec .inner .second-prsn-box {
  position: absolute;
  top: 20px;
  right: 20px;
}

.right-sec .inner .bottom-sec {
  position: absolute;
  left: 0;
  bottom: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-sec .inner .bottom-sec .name {
  position: absolute;
  bottom: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  padding: 2px 20px 5px;
}

.resize-icon-white {
  cursor: pointer;
  // background-image: url(./assets/icons/resize-icon.png);
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.camera-icon-black {
  cursor: pointer;
  // background-image: url(./assets/icons/camera-icon-sml.png);
  background-size: 50%;
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.speaker-icon-black {
  cursor: pointer;
  // background-image: url(./assets/icons/speaper-icon.png);
  background-size: 50%;
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.call-icon-red {
  cursor: pointer;
  // background: url("./assets/icons/call-icon-sml.png") no-repeat left 4px top 6px,
  //   linear-gradient(139deg, #de1a7c 0%, #ff0765 100%);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.crop-icon-blue {
  cursor: pointer;
  // background: url("./assets/icons/crop-icon-sml.png") no-repeat left 4px top 6px,
  //   linear-gradient(139deg, #de1ade 0%, #1e07ff 100%);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.eye-icon-gray {
  cursor: pointer;
  // background: url("./assets/icons/eye-icon-gray-sml.png") no-repeat left;
  width: 22px;
  height: 12px;
  display: inline-block;
}

.positioned-to-parent {
  position: absolute;
  right: 5px;
  top: 6px;
}

.right-sec .inner-2 .chat-box,
.common-chat.chat-box {
  border: 1px solid #dcdcdc;
  display: flex;
}

.right-sec .inner-2 .chat-box>.left,
.common-chat.chat-box>.left {
  width: 90px;
  float: left;
  display: flex;
  align-items: center;
  border-right: 1px solid #dcdcdc;
}

.switch-icon-blue {
  cursor: pointer;
  // background-image: url(./assets/icons/switch-icon-white.png);
  background-position: left 8px top 8px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-color: #2fa5df;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
}

.attach-icon-red {
  cursor: pointer;
  // background-image: url(./assets/icons/attach-icon-red.png);
  background-position: left top;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: 100%;
  display: inline-block;
}

.right-sec .inner-2 .chat-box .form-control,
.common-chat.chat-box .form-control {
  background-color: transparent;
  border: none;
  padding-right: 50px;
}

.right-sec .inner-2 .chat-box .form-control:focus,
.common-chat.chat-box .form-control {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.right-sec .inner-2 .chat-box>.right,
.common-chat.chat-box>.right {
  width: -webkit-fill-available;
  width: calc(100% - 90px);
}

.right-sec .inner-2 .chat-box .form-control::placeholder,
.common-chat.chat-box .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dcdcdc;
  opacity: 1;
  /* Firefox */
}

.right-sec .inner-2 .chat-box .form-control:-ms-input-placeholder,
.common-chat.chat-box .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dcdcdc;
}

.right-sec .inner-2 .chat-box .form-control::-ms-input-placeholder,
.common-chat.chat-box .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dcdcdc;
}

.w-60-p {
  width: 60% !important;
}

.bg-e1 {
  background-color: #e1dddd;
}

.plane-btn {
  // background-image: url(./assets/icons/plane.svg);
  background-repeat: no-repeat;
  background-position: left 8px top 8px;
  outline: none;
  background-size: 40%;
  border: none;
  background-color: #ababab;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  cursor: pointer;
}

.blue-success-msg {
  position: absolute;
  top: -70px;
  right: 0px;
  background-color: #2fa5df;
}

.blue-success-msg:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #2fa5df;
  display: inline-block;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  position: absolute;
  right: -2px;
  bottom: -2px;
}

.blue-chat-msg {
  background-color: #2fa5df;
  width: 85%;
  float: right;
  font-size: 0.9rem;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.blue-chat-msg:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #2fa5df;
  display: inline-block;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  position: absolute;
  right: 11px;
  bottom: -3px;
}

body {
  background-color: #fcfcfc;
}

.left-gray-box {
  min-height: 500px;
}

.left-gray-box .inner {
  padding: 15px;
}

// .dashed-brdr {
//   border-style: dashed !important;
// }
// .brdr-blue {
//   border-color: #0071a9 !important;
// }
// h6.dashed-title {
//   border-bottom: 1px dashed #707070;
//   padding-bottom: 10px;
// }
// .radius-50 {
//   border-radius: 50px;
//   -webkit-border-radius: 50px;
//   -moz-border-radius: 50px;
// }
.light-blue-template .bg-primary {
  background-color: #2fa5df !important;
}

.light-blue-template .btn-light-black {
  background-color: #707070;
  border: 1px solid #707070;
  color: #fff;
}

.light-blue-template .bg-light {
  background-color: #f5f5f5 !important;
}

.border-2 {
  border-width: 2px !important;
}

.brdr-d4 {
  border-color: #d4d4d4 !important;
}

.txt-blue {
  color: #0071a9;
}

.txt-light-blue {
  color: #2fa5df;
}

.bg-f9 {
  background-color: #f9f9f9 !important;
}

.brdr-ab {
  border-color: #ababab !important;
}

.input-with-label-group {
  padding: 3px 3px 3px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-with-label-group .left {
  display: flex;
  align-items: center;
  width: 90%;
}

.input-with-label-group .left>label {
  display: inline-block;
  font-size: 0.7em;
  color: #707070;
  width: auto;
  margin-bottom: 0;
  margin-right: 3px;
}

.input-with-label-group .form-control {
  color: #2fa5df;
  font-size: 0.7em;
  width: 70%;
  padding: 0;
  background-color: transparent;
  border: 0;
  height: 20px;
}

.input-with-label-group .form-control:focus {
  box-shadow: none;
}

// .custom-checkbox input[type="checkbox"],
// .custom-radio input[type="radio"] {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   position: absolute;
//   left: -2px;
//   border-color: white;
// }
.custom-checkbox label,
.custom-radio label {
  border: 1px solid #21a947;
  padding: 5px 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  cursor: pointer;
  width: 27px;
  display: block;
  height: 27px;
  margin-bottom: 0;
  background-image: url(./assets/icons/tick.png);
  background-repeat: no-repeat;
  margin-bottom: 0;
  background-position: left 6px top 8px;
  background-size: 50%;
}

.custom-checkbox label.sml,
.custom-radio label.sml {
  width: 15px;
  height: 15px;
  padding: 0;
  background-position: left 3px top 4px;
}

.custom-checkbox label.lrg,
.custom-radio label.lrg {
  width: 50px;
  height: 50px;
  padding: 0;
  background-position: left 12px top 15px;
  background-color: #fff;
}

.custom-checkbox input[type="checkbox"]:checked+label,
.custom-radio input[type="radio"]:checked+label {
  background-color: #21a947;
  border: 1px solid #21a947;
  background-image: url(./assets/icons/tick-white.png);
}

.custom-radio label.cancel-icon {
  border: 1px solid #ee135d;
  background-image: url(./assets/icons/cancel.svg);
  background-size: 40%;
  background-position: left 8px top 8px;
}

.custom-radio input[type="radio"]:checked+label.cancel-icon {
  background-color: #ee135d;
  border: 1px solid #ee135d;
  background-image: url(./assets/icons/cancel-white.svg);
}

.tick-icon-green-sml {
  background-image: url(./assets/icons/tick-white.png);
  background-position: left 3px top 5px;
  background-size: 50%;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  background-color: #21a947;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
}

.txt-green {
  color: #21a947;
}

.fnt-sml {
  font-size: 0.7rem;
}

.fnt-sml-x {
  font-size: 0.6rem;
}

// .right-sec .inner {
//   position: relative;
//   min-height: 400px;
// }
.rounded-15 {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

.right-sec .inner .second-prsn-box {
  position: absolute;
  top: 20px;
  right: 20px;
}

.right-sec .inner .bottom-sec {
  position: absolute;
  left: 0;
  bottom: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-sec .inner .bottom-sec .name {
  position: absolute;
  bottom: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  padding: 2px 20px 5px;
}

.resize-icon-white {
  cursor: pointer;
  // background-image: url(./assets/icons/resize-icon.png);
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.camera-icon-black {
  cursor: pointer;
  // background-image: url(./assets/icons/camera-icon-sml.png);
  background-size: 50%;
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.speaker-icon-black {
  cursor: pointer;
  // background-image: url(./assets/icons/speaper-icon.png);
  background-size: 50%;
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.call-icon-red {
  cursor: pointer;
  // background: url("./assets/icons/call-icon-sml.png") no-repeat left 4px top 6px, linear-gradient(139deg, #DE1A7C 0%, #FF0765 100%);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.crop-icon-blue {
  cursor: pointer;
  // background: url("./assets/icons/crop-icon-sml.png") no-repeat left 4px top 6px, linear-gradient(139deg, #DE1ADE 0%, #1E07FF 100%);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.eye-icon-gray {
  cursor: pointer;
  // background: url("./assets/icons/eye-icon-gray-sml.png") no-repeat left;
  width: 22px;
  height: 12px;
  display: inline-block;
}

.positioned-to-parent {
  position: absolute;
  right: 5px;
  top: 6px;
}

.right-sec .inner-2 .chat-box {
  border: 1px solid #dcdcdc;
  display: flex;
}

.right-sec .inner-2 .chat-box>.left {
  width: 90px;
  float: left;
  display: flex;
  align-items: center;
  border-right: 1px solid #dcdcdc;
}

.switch-icon-blue {
  cursor: pointer;
  // background-image: url(./assets/icons/switch-icon-white.png);
  background-position: left 8px top 8px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-color: #2fa5df;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
}

.attach-icon-red {
  cursor: pointer;
  // background-image: url(./assets/icons/attach-icon-red.png);
  background-position: left top;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: 100%;
  display: inline-block;
}

.right-sec .inner-2 .chat-box .form-control {
  background-color: transparent;
  border: none;
  padding-right: 50px;
}

.right-sec .inner-2 .chat-box .form-control:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.right-sec .inner-2 .chat-box>.right {
  width: -webkit-fill-available;
}

.right-sec .inner-2 .chat-box .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dcdcdc;
  opacity: 1;
  /* Firefox */
}

.right-sec .inner-2 .chat-box .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dcdcdc;
}

.right-sec .inner-2 .chat-box .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dcdcdc;
}

.w-60-p {
  width: 60% !important;
}

.bg-e1 {
  background-color: #e1dddd;
}

.plane-btn {
  // background-image: url(./assets/icons/plane.svg);
  background-repeat: no-repeat;
  background-position: left 8px top 8px;
  outline: none;
  background-size: 40%;
  border: none;
  background-color: #ababab;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  cursor: pointer;
}

.blue-success-msg {
  position: absolute;
  top: -70px;
  right: 0px;
  background-color: #2fa5df;
}

.blue-success-msg:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #2fa5df;
  display: inline-block;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  position: absolute;
  right: -2px;
  bottom: -2px;
}

// .prcntg-in-circle {

// }
.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.single-chart {
  width: 50px;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 100%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.green .circle {
  stroke: #21a947;
}

.percentage {
  fill: #21a947;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.txt-70 {
  color: #707070;
}

.modal-blk-trnsprnt {
  background-color: rgba(0, 0, 0, 0.2);
  border: 0;
}

.modal-blk-trnsprnt .close {
  opacity: 7;
}

.modal-blk-trnsprnt .close-btn {
  font-size: 1rem;
  text-shadow: 0 0 black;
  color: #fff;
  background-color: red;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
}

.p-10 {
  padding: 10px !important;
}

.txt-666 {
  color: #666666;
}

.cstmr-dtls-bx {
  box-shadow: 0px 1px 1px #00000029;
}

.nav-tabs .nav-link {
  background-color: #f8f8f8;
  border: 1px solid #ababab66;
  color: #707070;
  border-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
}

.nav-tabs .nav-link.active {
  border: none;
  color: #60c1f1;
  font-weight: bold;
  height: 53px;
  margin-top: -12px;
  padding-top: 17px;
}

.nav-tabs {
  position: relative;
  z-index: 1;
}

.txt-70 {
  color: #707070;
}

.bg-ab {
  background-color: #ababab !important;
}

.txt-007 {
  color: #0071a9;
}

.bg-f9 {
  background-color: #f9f9f9;
}

.brdr-white {
  border-color: white;
}

.notification-msg {
  background-color: #60c1f1;
  border: 1px solid #e1dddd;
}

.notification-msg:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #60c1f1;
  top: -7px;
  position: absolute;
  right: 13px;
  z-index: 2;
}

.notification-msg:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #e1dddd;
  top: -8px;
  z-index: 1;
  position: absolute;
  right: 12px;
}

.notification-msg.bg-ab:after {
  border-bottom-color: #ababab;
  box-shadow: 0px 0px 0px 0px #fff;
  z-index: 2;
}

.notification-msg.bg-ab:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #e1dddd;
  top: -8px;
  z-index: 1;
  position: absolute;
  right: 12px;
}

.fnt-8rem {
  font-size: 0.8rem;
}

#start-video-loader {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: absolute;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.camera-loader-icon {
  // background-image: url(./assets/icons/refresh.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 214px;
  height: 214px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*23-Nov-2020*/
.left-gray-box .inner h5 {
  font-family: poppins;
}

.part-gray-box .inner h5 {
  font-family: poppins;
}

.part-gray-box .inner {
  padding: 15px;
}

.btn-custom.btn-dark {
  font-family: Segoi-ui;
}

/*23-Nov-2020*/
/*25-Nov-2020*/
.big-nav-tabs .nav-item {
  min-width: 330px;
}

.big-nav-tabs .nav-link {
  background-color: transparent;
  color: #fff;
  border: none !important;
  border-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px !important;
  border-top-right-radius: 12px;
  -webkit-border-top-right-radius: 12px;
  -moz-border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  -webkit-border-top-left-radius: 12px;
  -moz-border-top-left-radius: 12px;
  font-family: poppins;
}

.big-nav-tabs .nav-link.active {
  border: 1px solid #ababab !important;
  border-bottom: 0 !important;
  background-color: #f8f8f8;
  color: #fff;
  font-weight: normal;
  height: auto;
  margin-top: 0;
  padding-top: 17px;
}

.bg-light-blue {
  background-color: #60c1f1 !important;
}

.fnt-roboto-bld {
  font-family: Roboto;
  font-weight: 700;
}

.fnt-3rem {
  font-size: 3rem;
}

.radius-9 {
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
}

.brdr-1 {
  border: 1px solid;
}

.bg-f8 {
  background-color: #f8f8f8;
}

.rounded-tab-content {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-top-left-radius: 0;
}

.rounded-12 {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
}

.btn-primary-cstm {
  background-color: #60c1f1;
  border: 1px solid #27abec;
}

.btn-outline-primary-cstm {
  border: 1px solid #60c1f1;
  color: #60c1f1;
  background-color: white;
}

.bg-red {
  background-color: #ee135d;
}

.txt-light-blue2 {
  color: #60c1f1;
}

.border-left-0 {
  border-left: 0 !important;
}

.brdr-e1 {
  border-color: #e1dddd;
}

.brdr-e1-right {
  border-right: 1px solid #e1dddd;
}

.cursor-pointer {
  cursor: pointer;
}

.cstm-row-tbl:nth-child(odd) .tbl-row {
  background-color: #e1dddd4d;
}

.cstm-row-tbl:nth-child(even) .tbl-row {
  background-color: #fff;
}

.schedular-popup-box {
  background-color: #fff;
  border: 1px solid #eceaea;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  right: 35px;
  top: 0;
  min-width: 800px;
  z-index: 1;
}

.schedular-popup-box.filter-box {
  min-width: 450px;
}

.light-blue-sml-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #27abec;
  width: 15px;
  height: 15px;
}

.brdr-ec {
  border-color: #eceaea;
}

.blnk-img {
  background-color: #e1dddd80;
  width: 45px;
  height: 45px;
  display: inline-block;
}

.flex-flow-wrap {
  flex-flow: wrap;
}

.light-input {
  border: 1px solid #fff;
  padding: 8px 15px;
  border: 1px solid #d6d6d6;
  background-color: transparent;
  font-size: 0.8rem;
  color: #fff;
  position: relative;
  z-index: 1;
}

.light-input::placeholder {
  color: #fff;
}

.light-input:focus {
  background-color: transparent;
}

.calander-icon-blue {
  cursor: pointer;
  background-image: url(./assets/icons/calendar-icon-blue.svg);
  background-position: left 5px top 10px;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -3px;
}

.time-icon-blue {
  cursor: pointer;
  background-image: url(./assets//icons//clock-icon-blue.svg);
  background-position: left 5px top 9px;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -3px;
}

.btn-primary-cstm:disabled {
  opacity: 0.3;
}

.bg-orange {
  background-color: #ffb03a;
}

.bg-red-tab {
  background-color: #ee135d;
}

.customer-flow-section {
  max-width: 460px;
  padding-bottom: 60px;
}

.otp-box-2 input {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e8e8e8;
  float: left;
  margin: 5px;
}

.otp-box-2 input:focus {
  box-shadow: none;
  border-color: #ccc;
}

.code-box input {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e8e8e8;
  float: left;
  margin: 5px;
}

.code-box input:focus {
  box-shadow: none;
  border-color: #ccc;
}

.bg-blue {
  background-color: #217dc3;
}

.gray-circle-btns span.bg-blue {
  background-color: #2fa5df;
}

.cstm-navbar .dropdown-menu {
  font-size: 0.7rem;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  border: none;
}

.cstm-navbar .dropdown-menu:before {
  content: "";
  position: absolute;
  right: 20px;
  top: -8px;
  width: 8px;
  height: 8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
}

.cstm-navbar .dropdown-menu .dropdown-item:nth-child(even) {
  background-color: rgba(226, 226, 226, 0.17);
}

.cstm-navbar .dropdown-menu .dropdown-item:nth-child(odd) {
  background-color: rgba(226, 226, 226, 1);
}

.cstm-navbar .dropdown-menu .dropdown-item {
  background-color: #2fa5df;
  color: #666666;
  text-transform: uppercase;
  font-family: Roboto-BoldCondensed;
}

.cstm-navbar .dropdown-menu .dropdown-item:hover {
  background-color: #2fa5df;
  color: #fff;
}

.cstm-navbar {
  height: 60px;
}

.cstm-navbar .dropdown-toggle::after {
  color: #fff;
}

.fnt-1-25rem {
  font-size: 1.25rem;
}

.h-60 {
  height: 60px;
}

.container {
  max-width: 1260px;
}

.status.dropdown-toggle:after {
  display: none;
}

.status .dropdown-menu:before {
  content: "";
  display: none;
}

.cstm-arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid #fff;
}

.bg-light-purple {
  background-color: #d635ef;
}

.events.big .the-event-name {
  padding: 5px 10px;
}

.events {
  height: 1px;
  position: absolute;
  width: 75%;
  left: 22.5%;
  top: -2%;
  z-index: 1;
  margin-bottom: 2px;
}

.the-event-name {
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
}

.right-sidebar .timeline-box .cstm-hr {
  height: 1px;
}

.right-sidebar .timeline-box .col-md-3 {
  font-size: 0.6rem;
  padding: 0px 5px;
}

.sidebar-calendar .calendar_weekdays>div {
  color: #707070 !important;
}

.sidebar-calendar .calendar_header h2 {
  text-transform: capitalize !important;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  color: #707070 !important;
  font-family: Roboto-BoldCondensed;
}

.sidebar-calendar .calendar_header {
  border-bottom: 0;
  width: 160px;
  margin-left: 15px;
  display: inline-flex;
  align-items: center;
}

.sidebar-calendar {
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  position: relative;
  height: 110px;
  overflow: hidden;
}

.big-nav-tabs .nav-item {
  width: 22%;
}

.big-nav-tabs .nav-link {
  background-color: transparent;
  color: #fff;
  border: none !important;
  border-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px !important;
  border-top-right-radius: 12px;
  -webkit-border-top-right-radius: 12px;
  -moz-border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  -webkit-border-top-left-radius: 12px;
  -moz-border-top-left-radius: 12px;
  font-family: poppins-regular;
}

.big-nav-tabs .nav-link.active {
  border: 1px solid #ababab !important;
  border-bottom: 0 !important;
  background-color: #f8f8f8;
  color: #fff;
  font-weight: normal;
  height: auto;
  margin-top: 0;
  padding-top: 17px;
}

.bg-light-blue {
  background-color: #60c1f1 !important;
}

.fnt-roboto-bld {
  font-family: Roboto-bold;
}

.fnt-3rem {
  font-size: 3rem;
}

.radius-9 {
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
}

.brdr-1 {
  border: 1px solid;
}

.bg-f8 {
  background-color: #f8f8f8;
}

.rounded-tab-content {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-top-left-radius: 0;
}

.rounded-12 {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
}

.btn-primary-cstm {
  background-color: #60c1f1;
  border: 1px solid #27abec;
}

.btn-outline-primary-cstm {
  border: 1px solid #60c1f1;
  color: #60c1f1;
  background-color: white;
}

.bg-red {
  background-color: #ee135d;
}

.txt-light-blue2 {
  color: #60c1f1 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.brdr-e1 {
  border-color: #e1dddd;
}

.brdr-e1-right {
  border-right: 1px solid #e1dddd;
}

.cursor-pointer {
  cursor: pointer;
}

.cstm-row-tbl:nth-child(odd) .tbl-row {
  background-color: #e1dddd4d;
}

.cstm-row-tbl:nth-child(even) .tbl-row {
  background-color: #fff;
}

.schedular-popup-box {
  background-color: #fff;
  border: 1px solid #eceaea;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  right: 35px;
  top: 0;
  min-width: 350px;
  z-index: 1;
}

.light-blue-sml-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #27abec;
  width: 15px;
  height: 15px;
}

.brdr-ec {
  border-color: #eceaea;
}

.blnk-img {
  background-color: #e1dddd80;
  width: 45px;
  height: 45px;
  display: inline-block;
}

.flex-flow-wrap {
  flex-flow: wrap;
}

.light-input {
  border: 1px solid #fff;
  padding: 8px 15px;
  border: 1px solid #d6d6d6;
  background-color: transparent;
  font-size: 0.8rem;
  color: #fff;
  position: relative;
  z-index: 1;
}

.light-input::placeholder {
  color: #fff;
}

.light-input:focus {
  background-color: transparent;
}

.calander-icon-blue {
  cursor: pointer;
  // background-image: url(./assets/icons/calendar-icon-blue.svg);
  background-position: left 5px top 10px;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -3px;
}

.time-icon-blue {
  cursor: pointer;
  // background-image: url(./assets/icons/clock-icon-blue.svg);
  background-position: left 5px top 9px;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -3px;
}

.btn-primary-cstm:disabled {
  opacity: 0.3;
}

.bg-orange {
  background-color: #ffb03a;
}

.bg-light-purple {
  background-color: #606af1;
}

.bg-red-tab {
  background-color: #ee135d;
}

.brdr-eb {
  border-color: #ebebeb;
}

.cstm-shdo {
  box-shadow: 0px 1px 2px #00000029;
  -webkit-box-shadow: 0px 1px 2px #00000029;
  -moz-box-shadow: 0px 1px 2px #00000029;
}

.txt-gray {
  color: #666666;
}

.customer-name-blue {
  color: #4589d8;
}

.orange-circle-hand {
  background-color: #ff8f1e;
  width: 55px;
  height: 55px;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  margin-top: -15px;
}

.orange-circle-hand.disabled {
  opacity: 0.5;
}

/*Calendar*/
.calendar,
.calendar_weekdays,
.calendar_content {
  max-width: 300px;
}

.calendar {
  margin: auto;
  font-family: "Muli", sans-serif;
  font-weight: 400;
}

.calendar_content,
.calendar_weekdays,
.calendar_header {
  position: relative;
  overflow: hidden;
}

.calendar_weekdays div {
  display: inline-block;
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.calendar_weekdays div,
.calendar_content div {
  width: 14.28571%;
  overflow: hidden;
  text-align: center;
  background-color: transparent;
  color: #6f6f6f;
  font-size: 0.7rem;
  box-sizing: border-box;
}

.calendar_content div {
  border: 1px solid transparent;
  float: left;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.calendar_content div:hover {
  border: 1px solid #dcdcdc;
  cursor: default;
}

.calendar_content div.blank:hover {
  cursor: default;
  border: 1px solid transparent;
}

.calendar_content div.past-date {
  color: #d5d5d5;
}

.calendar_content div.today {
  font-weight: bold;
  color: #fff !important;
  position: relative;
  top: -5px;
}

.calendar_content div.today span {
  background-color: #60c1f1;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_content div.selected {
  background-color: #f0f0f0;
}

.calendar_header {
  width: 100%;
  text-align: center;
  border-bottom: 1px dashed #60c1f1;
  margin-bottom: 15px;
}

.calendar_header h2 {
  padding: 0 10px;
  font-family: "Muli", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #60c1f1;
  float: left;
  width: 70%;
  margin: 0 0 10px;
}

button.switch-month {
  background-color: transparent;
  padding: 0;
  outline: none;
  border: none;
  color: #dcdcdc;
  float: left;
  width: 15%;
  transition: color 0.2s;
}

button.switch-month:hover {
  color: #87b633;
}

.switch-left {
  text-align: left;
}

.switch-right {
  text-align: right;
}

.calendar_content span {
  width: 25px;
  height: 25px;
}

/*Calendar*/
.brdr-light-blue {
  border-color: #60c1f1 !important;
}

.calendar_weekdays>div {
  color: #60c1f1 !important;
}

.xtra-light-black-txt {
  opacity: 0.36;
}

.brdr-70 {
  border-color: #707070 !important;
}

.timeline-box .cstm-hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #e1dddd;
  height: 2px;
  margin-left: -8px;
}

.timeline-box .red-indicator {
  height: 1px;
  position: absolute;
  width: 75%;
  background-color: #ee135d;
  left: 22.5%;
  top: 10%;
  z-index: 1;
}

.timeline-box .red-indicator:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50px;
  display: inline-block;
  background-color: #ee135d;
  position: absolute;
  left: -2px;
  top: -2px;
}

.timeline-box .red-indicator:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  display: inline-block;
  background-color: #ee135d;
  position: absolute;
  right: -3px;
  top: -4px;
}

.brdr-2 {
  border-width: 2px !important;
}

.cstm-nav-tabs-sml .nav-link {
  font-family: poppins-regular;
  background-color: #f5f4f4;
}

.cstm-nav-tabs-sml .nav-link.active {
  padding: 0;
  border: 1px solid #4589d8;
  border-bottom: 0;
  height: 45px;
}

.cstm-nav-tabs-sml .nav-link.active>div {
  border-bottom: 2px solid #4589d8;
  color: #4589d8;
  font-weight: bold;
  margin-top: -12px;
  padding-top: 15px;
  padding-bottom: 11px;
  border-radius: 0;
  margin: 0 3px;
  padding-left: 30px;
  padding-right: 30px;
}

.brdr-blue-2 {
  border-color: #4589d8;
}

/*27-Nov-2020*/
/*03-Dec-2020*/
.bg-blue {
  background-color: #217dc3;
}

.bg-purple {
  background-color: #e61a80;
}

.fnt-1-25rem {
  font-size: 1.25rem;
}

.cstm-navbar {
  height: 60px;
}

.h-60 {
  height: 60px;
}

.cstm-navbar .dropdown-toggle::after {
  color: #fff;
}

section.main-content {
  width: 100%;
  box-sizing: border-box;
  float: left;
  /*padding-left: 35px;
  padding-right: 35px;*/
  /*margin-left: 70px;*/
  padding-left: 70px;
  padding-right: 30px;
  transition: ease 0.2s;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
}

section.main-content.auto-adjust {
  width: 80%;
}

.wrapper {
  height: inherit;
}

.ml--30 {
  margin-left: -30px;
}

.mr--30 {
  margin-right: -30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.big-nav-tabs-2 {
  cursor: pointer;
  width: auto;
  float: left;
}

.big-nav-tabs-2 .nav-item {
  width: auto;
  margin-right: 20px;
}

.big-nav-tabs-2 .nav-item.active {
  border-radius: 0;
  background-color: transparent;
}

.big-nav-tabs-2 .nav-item:nth-child(2) .nav-link.active {
  border-bottom: 3px solid #11c417 !important;
}

.big-nav-tabs-2 .nav-item:nth-child(1) .nav-link>span {
  background-color: #2680eb;
  border: 0.5px solid #2680eb;
}

.big-nav-tabs-2 .nav-item:nth-child(2) .nav-link>span {
  background-color: #11c417;
  border: 0.5px solid #11c417;
}

.big-nav-tabs-2 .nav-item:nth-child(3) .nav-link>span {
  background-color: #ebebeb;
}

.live-calls-icon {
  position: relative;
}

.live-calls-icon:after {
  content: "";
  width: 60px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  display: inline-block;
  top: 0;
  transform: rotate(120deg);
  top: 14px;
  left: -15px;
}

.nav-tabs-plain {
  border-color: #dbdbdb !important;
  width: auto;
  float: left;
}

.nav-tabs-plain li {
  margin-left: 25px;
  font-size: 0.8rem !important;
  font-family: Roboto-bold;
  color: #666666;
  cursor: pointer;
}

.nav-tabs-plain li:last-child {
  margin-right: 25px;
}

.nav-tabs-plain .nav-link {
  border: none;
}

.nav-tabs-plain .nav-link.active {
  border-bottom: 3px solid #2680eb;
}

.gray-circle-btns>span {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  background-color: #e2e2e2;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.gray-circle-btns>span img,
.gray-circle-btns>span svg {
  height: 15px;
}

.gray-circle-btns span.bg-blue {
  background-color: #2fa5df;
}

.custom-checkbox-rounded-square input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  left: -2px;
  border-color: white;
}

.custom-checkbox-rounded-square label {
  box-sizing: border-box;
  border: 1px solid #707070;
  padding: 5px;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 7px;
  color: transparent;
  width: 10px;
  height: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox-rounded-square label:hover {
  background-color: #fff;
  border: 1px solid #5fc9d8;
}

.custom-checkbox-rounded-square input[type="checkbox"]:checked+label {
  background-color: #d7fffb;
  color: #333;
  border: 1px solid #5fc9d8;
}

.sml-blue-header thead th {
  color: #2680eb;
  font-size: 0.6rem !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Roboto-bold;
  border: none;
  padding: 5px;
  font-weight: normal;
}

.sml-blue-header tbody td {
  vertical-align: middle;
  font-size: 0.7rem !important;
  color: #666666;
  border: none;
  padding: 5px;
}

.sml-blue-header thead th:last-child {
  text-align: center !important;
}

.user-blue-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #27abec;
  width: 30px;
  height: 30px;
  color: #fff;
}

.tbl-row {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-bottom: 10px;
}

.icon-light-blue path,
.icon-light-blue g circle {
  fill: #27abec;
}

.status {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  padding: 3px 10px;
}

.status.complete {
  background-color: #c1f0c3 !important;
  color: #11c417 !important;
}

.status.none {
  background-color: #929292;
  color: #fff;
}

.status.danger {
  background-color: #f0c1c9 !important;
  color: #c4112f !important;
}

.sidebar {
  width: 70px;
  height: inherit;
  float: left;
  background-color: #eeeeee;
  margin-top: 0px;
  position: fixed;
  max-height: inherit;
  overflow-y: auto;
  overflow-x: hidden;

  transition: ease 0.2s;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
}

.sidebar.active {
  width: 235px;
  display: block;
  transition: ease 0.2s;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
}

.sidebar>ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.sidebar>ul>li:last-child .dropdown-menu-2020 {
  margin-bottom: 100px;
}

.sidebar>ul>li {
  min-height: 69px;
  list-style: none;
  border-bottom: 1px dotted #ccc;
  width: 235px !important;
  overflow: hidden;
  font-size: 0.8rem;
}

.sidebar>ul>li:hover {
  color: #40aefd;
}

.sidebar>ul>li>a {
  box-sizing: border-box;
  padding: 15px;
  padding-left: 22px;
  float: left;
  width: 100%;
  color: #666;
  display: inline-flex;
  align-items: center;
  text-align: center;
}

.sidebar>ul>li>a:hover {
  color: #40aefd;
}

.sidebar>ul>li>a .light-blue {
  fill: #a7a7a7;
}

.sidebar>ul>li:hover {
  background-color: #f5f5f5;
}

.sidebar>ul>li.active {
  background-color: #f5f5f5;
}

.sidebar>ul>li.active a {
  color: #40aefd;
}

.sidebar>ul>li.active a .light-blue {
  fill: #40aefd !important;
}

.sidebar>ul>li span {
  margin-right: 10px;
}

.sidebar>ul>li span.icon {
  padding: 5px;
  float: left;
  background-position: center center;
  width: 27px;
  height: 27px;
  display: inline-block;
  line-height: 14px;
  vertical-align: text-top;
  background-repeat: no-repeat;
}

.sidebar>ul>li:hover span.title {
  color: #40aefd;
}

.sidebar>ul>li span.workoholic-icon {
  // background-image: url(./assets/icons/workoholic-icon-gray.png);
}

.sidebar>ul>li.active span.workoholic-icon {
  // background-image: url(./assets/icons/workoholic-icon-blue.png);
}

.sidebar>ul>li:hover span.workoholic-icon {
  // background-image: url(./assets/icons/workoholic-icon-blue.png);
}

.sidebar>ul>li span.title {
  float: left;
  padding: 10px 0px;
  opacity: 0;
  margin-left: 20px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

.sidebar.active>ul>li span.title {
  display: block;
  margin-left: 0;
  opacity: 10;
}

.sidebar ul>.dropdown>a:after {
  content: "";
  width: 8px;
  height: 10px;
  float: right;
  // background-image: url(./assets/icons/arrow-right-icon.png);
  background-repeat: no-repeat;
  background-position: top right;
  top: 13px;
  right: 10px;
  position: relative;
  transform: rotate(-30deg);
}

.sidebar ul>.dropdown>a.active:after {
  content: "";
  width: 8px;
  height: 10px;
  float: right;
  // background-image: url(./assets/icons/arrow-right-icon.png);
  background-repeat: no-repeat;
  background-position: top right;
  top: 13px;
  right: 10px;
  position: relative;
  transform: rotate(30deg);
}

/*.ml240 {
    margin-left: 240px !important;
}*/
.ml240 {
  padding-left: 235px !important;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.cstm-navbar .dropdown-menu {
  font-size: 0.7rem;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  border: none;
}

.cstm-navbar .dropdown-menu:before {
  content: "";
  position: absolute;
  right: 20px;
  top: -8px;
  width: 8px;
  height: 8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
}

.cstm-navbar .dropdown-menu .dropdown-item:nth-child(even) {
  background-color: rgba(226, 226, 226, 0.17);
}

.cstm-navbar .dropdown-menu .dropdown-item:nth-child(odd) {
  background-color: rgba(226, 226, 226, 1);
}

.cstm-navbar .dropdown-menu .dropdown-item {
  background-color: #2fa5df;
  color: #666666;
  text-transform: uppercase;
  font-family: Roboto-BoldCondensed;
}

.cstm-navbar .dropdown-menu .dropdown-item:hover {
  background-color: #2fa5df;
  color: #fff;
}

.red-close {
  background-color: #c4112f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.bg-f5 {
  background-color: #f5f5f5;
}

.brdr-e2 {
  border-color: #e2e2e2;
}

.cstmr-dtls-popup-box {
  font-family: Roboto-Condensed;
}

.txt-92 {
  color: #929292;
}

.txt-blue-dull {
  color: #217dc3;
}

.cstmr-dtls-popup-box .basic-dtls {
  color: #666666;
  font-size: 0.8rem;
}

.cstmr-dtls-popup-box .basic-dtls table td {
  border-bottom: 1px dashed #e2e2e2;
  padding: 5px 5px;
}

#cstmrDtls .modal-dialog {
  max-width: 700px;
}

.dahsed-h-line {
  width: auto;
  height: 1px;
  border-bottom: 1px dashed #c7c5c5;
}

.bg-e2 {
  background-color: #e2e2e2;
}

.txt-c7 {
  color: #c7c5c5;
}

.schedular-popup-box h6 {
  font-family: poppins-Medium;
}

.schedular-popup-box:before {
  content: "";
  position: absolute;
  right: -6px;
  top: 10px;
  width: 6px;
  height: 6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
}

.transfer-box:before {
  content: "";
  position: absolute;
  right: -6px;
  top: 10px;
  width: 6px;
  height: 6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
}

.transfer-box,
.menu-box {
  box-shadow: 0px 3px 6px #00000029;
}

.transfer .dropdown-menu,
.menu-icon .dropdown {
  font-size: 0.7rem;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  border: none;
}

.transfer .dropdown-menu .dropdown-item:nth-child(odd),
.menu-icon .dropdown-menu .dropdown-item:nth-child(odd) {
  background-color: rgba(226, 226, 226, 0.17);
}

.transfer .dropdown-menu .dropdown-item:nth-child(even),
.menu-icon .dropdown-menu .dropdown-item:nth-child(even) {
  background-color: rgba(226, 226, 226, 1);
}

.transfer .dropdown-menu .dropdown-item {
  background-color: #2fa5df;
  color: #666666;
  font-family: Roboto-regular;
  padding-left: 10px;
}

.transfer .dropdown-menu .dropdown-item:hover,
.menu-icon .dropdown-menu .dropdown-item:hover {
  background-color: #2fa5df;
  color: #fff;
}

.transfer .dropdown-menu .dropdown-item>span {
  padding: 10px;
}

.call-white-icon path {
  fill: #fff;
}

.menu-icon .dropdown-menu .dropdown-item {
  background-color: #2fa5df;
  color: #666666;
  font-family: Roboto-CondensedItalic;
  padding-left: 5px;
  display: inline-flex;
  font-style: italic;
}

.menu-icon .dropdown-menu .dropdown-item>span {
  padding: 0;
}

.menu-icon .dropdown-menu .dropdown-item>span:first-child {
  margin-right: 5px;
}

.menu-icon .dropdown-menu .dropdown-item:hover .reschedule-call-icon svg path {
  fill: #fff;
}

.reschedule-call-icon svg {
  width: 13px;
}

.back-assign-icon svg {
  width: 13px;
}

.transfer-menu-icon svg {
  width: 13px;
}

.back-to-call-icon svg {
  width: 13px;
}

.menu-icon .dropdown-menu .dropdown-item:hover .reschedule-call-icon svg path,
.menu-icon .dropdown-menu .dropdown-item:hover .back-to-call-icon svg path,
.menu-icon .dropdown-menu .dropdown-item:hover .transfer-menu-icon svg path,
.menu-icon .dropdown-menu .dropdown-item:hover .back-assign-icon svg path {
  fill: #fff;
}

.back-to-call-icon svg path {
  fill: #666;
}

.right-sidebar {
  background-color: #fbfbfb;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #eeeeee;
  padding-left: 30px;
  position: fixed;
  top: 60px;
  right: 0;
  height: 100%;
}

.right-sidebar .calendar-icon {
  background-color: #fbfbfb;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -50px;
  top: 20px;
  padding: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.right-sidebar .calendar-icon img {
  width: 23px;
}

.right-sidebar .right-sidebar-inner {
  position: relative;
  background-color: #fff;
  border: 1px solid #f0f0f0;
}

.right-sidebar.active {
  width: 320px;
  padding-left: 0;
  padding: 10px;
  padding-top: 0;
}

.right-sidebar.active .calendar-icon {
  right: 280px;
  z-index: 3;
}

.prtcpnt-drpdn li>span {
  font-family: Roboto-Condensed;
}

.prtcpnt-drpdn li>span:last-child {
  font-family: Roboto-BoldCondensed;
}

.custom-dropdown-box .custom-select-drp {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 20px !important;
  justify-content: space-between;
}

.custom-dropdown-box .custom-dropdown-menu {
  margin: 0;
  padding-left: 0;
  cursor: pointer;
  background-color: white;
  display: none;
  padding: 10px;
  padding-bottom: 15px;
  padding-top: 0;
  border-bottom-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  left: 0px;
  background-color: #217dc3;
}

.custom-dropdown-menu>li {
  list-style: none;
  padding: 3px 10px;
  color: #666666;
  text-align: center;
  color: #666;
  font-size: 12px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
}

.custom-dropdown-menu>li:hover {
  opacity: 0.8;
  color: #333;
}

.custom-dropdown-menu>li:last-child {
  border: none;
}

.container {
  max-width: 1260px;
}

.light-blue-template header {
  box-shadow: 0px 1px 2px #00000029;
}

.left-gray-box {
  min-height: 500px;
}

.left-gray-box .inner {
  padding: 15px;
}

.btn-custom {
  padding-left: 25px;
  padding-right: 25px;
}

.ltr-spc-1 {
  letter-spacing: 1px;
}

.btn-custom:hover {
  opacity: 0.9;
}

.dashed-brdr {
  border-style: dashed !important;
}

.brdr-blue {
  border-color: #0071a9 !important;
}

h6.dashed-title {
  border-bottom: 1px dashed #707070;
  padding-bottom: 10px;
}

.radius-50 {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.light-blue-template .bg-primary {
  background-color: #2fa5df !important;
}

.light-blue-template .btn-light-black {
  background-color: #707070;
  border: 1px solid #707070;
  color: #fff;
}

.light-blue-template .bg-light {
  background-color: #f5f5f5 !important;
}

.border-2 {
  border-width: 2px !important;
}

.brdr-d4 {
  border-color: #d4d4d4 !important;
}

.txt-blue {
  color: #0071a9;
}

.txt-light-blue {
  color: #2fa5df !important;
}

.bg-f9 {
  background-color: #f9f9f9 !important;
}

.brdr-ab {
  border-color: #ababab !important;
}

.input-with-label-group {
  padding: 3px 3px 3px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-with-label-group .left {
  display: flex;
  align-items: center;
  width: 90%;
}

.input-with-label-group .left>label {
  display: inline-block;
  font-size: 0.7em;
  color: #707070;
  width: auto;
  margin-bottom: 0;
  margin-right: 3px;
}

.input-with-label-group .form-control {
  color: #2fa5df;
  font-size: 0.9em;
  width: 70%;
  padding: 0;
  background-color: transparent;
  border: 0;
  height: 20px;
}

.input-with-label-group .form-control:focus {
  box-shadow: none;
}

.custom-checkbox input[type="checkbox"],
.custom-radio input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  left: -2px;
  border-color: white;
}

.custom-checkbox label,
.custom-radio label {
  border: 1px solid #21a947;
  padding: 5px 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  cursor: pointer;
  width: 27px;
  display: block;
  height: 27px;
  margin-bottom: 0;
  background-image: url(./assets/icons/tick.png);
  background-repeat: no-repeat;
  margin-bottom: 0;
  background-position: left 6px top 8px;
  background-size: 50%;
}

.custom-checkbox label.sml,
.custom-radio label.sml {
  width: 15px;
  height: 15px;
  padding: 0;
  background-position: left 3px top 4px;
}

.custom-checkbox label.lrg,
.custom-radio label.lrg {
  width: 50px;
  height: 50px;
  padding: 0;
  background-position: left 12px top 15px;
  background-color: #fff;
}

.custom-checkbox input[type="checkbox"]:checked+label,
.custom-radio input[type="radio"]:checked+label {
  background-color: #21a947;
  border: 1px solid #21a947;
  background-image: url(./assets/icons/tick-white.png);
}

.custom-radio label.cancel-icon {
  border: 1px solid #ee135d;
  background-image: url(./assets/icons/cancel.svg);
  background-size: 40%;
  background-position: left 8px top 8px;
}

.custom-radio input[type="radio"]:checked+label.cancel-icon {
  background-color: #ee135d;
  border: 1px solid #ee135d;
  background-image: url(./assets/icons/cancel-white.svg);
}

.tick-icon-green-sml {
  background-image: url(./assets/icons/tick-white.svg);
  background-position: left 3px top 5px;
  background-size: 50%;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  background-color: #21a947;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
}

.txt-green {
  color: #21a947;
}

.fnt-sml {
  font-size: 0.7rem;
}

.fnt-sml-x {
  font-size: 0.6rem;
}

.right-sec .inner {
  position: relative;
  min-height: 400px;
}

.rounded-15 {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

.right-sec .inner .second-prsn-box {
  position: absolute;
  top: 20px;
  right: 20px;
}

.right-sec .inner .bottom-sec {
  position: absolute;
  left: 0;
  bottom: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-sec .inner .bottom-sec .name {
  position: absolute;
  bottom: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  padding: 2px 20px 5px;
}

.resize-icon-white {
  cursor: pointer;
  background-image: url(./assets/icons/resize-icon.png);
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.camera-icon-black {
  cursor: pointer;
  background-image: url(./assets/icons/camera-icon-sml.png);
  background-size: 50%;
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.speaker-icon-black {
  cursor: pointer;
  background-image: url(./assets/icons/speaper-icon.png);
  background-size: 50%;
  background-position: left 10px top 10px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.call-icon-red {
  cursor: pointer;
  background: url("./assets/icons/call-icon-sml.png") no-repeat left 4px top 6px,
    linear-gradient(139deg, #de1a7c 0%, #ff0765 100%);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.crop-icon-blue {
  cursor: pointer;
  background: url("./assets/icons/crop-icon-sml.png") no-repeat left 4px top 6px,
    linear-gradient(139deg, #de1ade 0%, #1e07ff 100%);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.eye-icon-gray {
  cursor: pointer;
  background: url("./assets/icons/eye-icon-gray-sml.png") no-repeat left;
  width: 22px;
  height: 12px;
  display: inline-block;
}

.positioned-to-parent {
  position: absolute;
  right: 5px;
  top: 6px;
}

.right-sec .inner-2 .chat-box,
.common-chat.chat-box {
  border: 1px solid #dcdcdc;
  display: flex;
}

.right-sec .inner-2 .chat-box>.left,
.common-chat.chat-box>.left {
  width: 90px;
  float: left;
  display: flex;
  align-items: center;
  border-right: 1px solid #dcdcdc;
}

.switch-icon-blue {
  cursor: pointer;
  background-image: url(./assets/icons/switch-icon-white.png);
  background-position: left 8px top 8px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-color: #2fa5df;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-block;
}

.attach-icon-red {
  cursor: pointer;
  background-image: url(./assets/icons/attach-icon-red.png);
  background-position: left top;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: 100%;
  display: inline-block;
}

.right-sec .inner-2 .chat-box .form-control,
.common-chat.chat-box .form-control {
  background-color: transparent;
  border: none;
  padding-right: 50px;
}

.right-sec .inner-2 .chat-box .form-control:focus,
.common-chat.chat-box .form-control {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.right-sec .inner-2 .chat-box>.right,
.common-chat.chat-box>.right {
  width: -webkit-fill-available;
}

.right-sec .inner-2 .chat-box .form-control::placeholder,
.common-chat.chat-box .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dcdcdc;
  opacity: 1;
  /* Firefox */
}

.right-sec .inner-2 .chat-box .form-control:-ms-input-placeholder,
.common-chat.chat-box .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dcdcdc;
}

.right-sec .inner-2 .chat-box .form-control::-ms-input-placeholder,
.common-chat.chat-box .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dcdcdc;
}

.w-60-p {
  width: 60% !important;
}

.bg-e1 {
  background-color: #e1dddd;
}

.plane-btn {
  background-image: url(./assets/icons/plane.svg);
  background-repeat: no-repeat;
  background-position: left 8px top 8px;
  outline: none;
  background-size: 40%;
  border: none;
  background-color: #ababab;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  cursor: pointer;
}

.blue-success-msg {
  position: absolute;
  top: -70px;
  right: 0px;
  background-color: #2fa5df;
}

.blue-success-msg:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #2fa5df;
  display: inline-block;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  position: absolute;
  right: -2px;
  bottom: -2px;
}

.blue-chat-msg {
  background-color: #2fa5df;
  width: 85%;
  float: right;
  font-size: 0.9rem;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.blue-chat-msg:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #2fa5df;
  display: inline-block;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  position: absolute;
  right: 11px;
  bottom: -3px;
}

.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.single-chart {
  width: 50px;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 100%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.green .circle {
  stroke: #21a947;
}

.percentage {
  fill: #21a947;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.txt-70 {
  color: #707070;
}

.modal-blk-trnsprnt {
  background-color: rgba(0, 0, 0, 0.2);
  border: 0;
}

.modal-blk-trnsprnt .close {
  opacity: 7;
}

.modal-blk-trnsprnt .close-btn {
  font-size: 1rem;
  text-shadow: 0 0 black;
  color: #fff;
  background-color: red;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
}

.p-10 {
  padding: 10px !important;
}

.txt-666 {
  color: #666666;
}

.cstmr-dtls-bx {
  box-shadow: 0px 1px 1px #00000029;
}

.nav-tabs-first .nav-link {
  background-color: #f8f8f8;
  border: 1px solid #ababab66;
  color: #707070;
  border-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
}

.nav-tabs-first .nav-link.active {
  border: none;
  color: #60c1f1;
  font-weight: bold;
  height: 53px;
  margin-top: -12px;
  padding-top: 17px;
}

.txt-70 {
  color: #707070;
}

.bg-ab {
  background-color: #ababab !important;
}

.txt-007 {
  color: #0071a9;
}

.bg-f9 {
  background-color: #f9f9f9;
}

.brdr-white {
  border-color: white;
}

.notification-msg {
  background-color: #60c1f1;
  border: 1px solid #e1dddd;
}

.notification-msg:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #60c1f1;
  top: -7px;
  position: absolute;
  right: 13px;
  z-index: 2;
}

.notification-msg:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #e1dddd;
  top: -8px;
  z-index: 1;
  position: absolute;
  right: 12px;
}

.notification-msg.bg-ab:after {
  border-bottom-color: #ababab;
  box-shadow: 0px 0px 0px 0px #fff;
  z-index: 2;
}

.notification-msg.bg-ab:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #e1dddd;
  top: -8px;
  z-index: 1;
  position: absolute;
  right: 12px;
}

.fnt-8rem {
  font-size: 0.8rem;
}

#start-video-loader {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: absolute;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.camera-loader-icon {
  background-image: url(./assets/icons/refresh.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 214px;
  height: 214px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*23-Nov-2020*/
.left-gray-box .inner h5 {
  font-family: poppins-regular;
}

.btn-custom.btn-dark {
  font-family: Segoi-ui;
}

/*23-Nov-2020*/
/*25-Nov-2020*/
.big-nav-tabs .nav-item {
  width: 22%;
}

.big-nav-tabs .nav-link {
  background-color: transparent;
  color: #fff;
  border: none !important;
  border-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px !important;
  border-top-right-radius: 12px;
  -webkit-border-top-right-radius: 12px;
  -moz-border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  -webkit-border-top-left-radius: 12px;
  -moz-border-top-left-radius: 12px;
  font-family: poppins-regular;
}

.big-nav-tabs .nav-link.active {
  border: 1px solid #ababab !important;
  border-bottom: 0 !important;
  background-color: #f8f8f8;
  color: #fff;
  font-weight: normal;
  height: auto;
  margin-top: 0;
  padding-top: 17px;
}

.bg-light-blue {
  background-color: #60c1f1 !important;
}

.fnt-roboto-bld {
  font-family: Roboto-bold;
}

.fnt-3rem {
  font-size: 3rem;
}

.radius-9 {
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
}

.brdr-1 {
  border: 1px solid;
}

.bg-f8 {
  background-color: #f8f8f8;
}

.rounded-tab-content {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-top-left-radius: 0;
}

.rounded-12 {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
}

.btn-primary-cstm {
  background-color: #60c1f1;
  border: 1px solid #27abec;
}

.btn-outline-primary-cstm {
  border: 1px solid #60c1f1;
  color: #60c1f1;
  background-color: white;
}

.bg-red {
  background-color: #ee135d;
}

.txt-light-blue2 {
  color: #60c1f1 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.brdr-e1 {
  border-color: #e1dddd;
}

.brdr-e1-right {
  border-right: 1px solid #e1dddd;
}

.cursor-pointer {
  cursor: pointer;
}

.cstm-row-tbl:nth-child(odd) .tbl-row {
  background-color: #e1dddd4d;
}

.cstm-row-tbl:nth-child(even) .tbl-row {
  background-color: #fff;
}

.schedular-popup-box {
  background-color: #fff;
  border: 1px solid #eceaea;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  right: 35px;
  top: 0;
  min-width: 350px;
  z-index: 1;
}

.light-blue-sml-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #27abec;
  width: 15px;
  height: 15px;
}

.brdr-ec {
  border-color: #eceaea;
}

.blnk-img {
  background-color: #e1dddd80;
  width: 45px;
  height: 45px;
  display: inline-block;
}

.flex-flow-wrap {
  flex-flow: wrap;
}

.light-input {
  border: 1px solid #fff;
  padding: 8px 15px;
  border: 1px solid #d6d6d6;
  background-color: transparent;
  font-size: 0.8rem;
  color: #fff;
  position: relative;
  z-index: 1;
}

.light-input::placeholder {
  color: #fff;
}

.light-input:focus {
  background-color: transparent;
}

.calander-icon-blue {
  cursor: pointer;
  background-image: url(./assets/icons/calendar-icon-blue.svg);
  background-position: left 5px top 10px;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -3px;
}

.time-icon-blue {
  cursor: pointer;
  background-image: url(./assets/icons/clock-icon-blue.svg);
  background-position: left 5px top 9px;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -3px;
}

.btn-primary-cstm:disabled {
  opacity: 0.3;
}

.bg-orange {
  background-color: #ffb03a;
}

.bg-light-purple {
  background-color: #606af1;
}

.bg-red-tab {
  background-color: #ee135d;
}

.brdr-eb {
  border-color: #ebebeb;
}

.cstm-shdo {
  box-shadow: 0px 1px 2px #00000029;
  -webkit-box-shadow: 0px 1px 2px #00000029;
  -moz-box-shadow: 0px 1px 2px #00000029;
}

.txt-gray {
  color: #666666;
}

.customer-name-blue {
  color: #4589d8;
}

.orange-circle-hand {
  background-color: #ff8f1e;
  width: 55px;
  height: 55px;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  margin-top: -15px;
}

.orange-circle-hand.disabled {
  opacity: 0.5;
}

/*Calendar*/
.calendar,
.calendar_weekdays,
.calendar_content {
  max-width: 300px;
}

.calendar {
  margin: auto;
  font-family: "Muli", sans-serif;
  font-weight: 400;
}

.calendar_content,
.calendar_weekdays,
.calendar_header {
  position: relative;
  overflow: hidden;
}

.calendar_weekdays div {
  display: inline-block;
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.calendar_weekdays div,
.calendar_content div {
  width: 14.28571%;
  overflow: hidden;
  text-align: center;
  background-color: transparent;
  color: #6f6f6f;
  font-size: 0.7rem;
  box-sizing: border-box;
}

.calendar_content div {
  border: 1px solid transparent;
  float: left;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.calendar_content div:hover {
  border: 1px solid #dcdcdc;
  cursor: default;
}

.calendar_content div.blank:hover {
  cursor: default;
  border: 1px solid transparent;
}

.calendar_content div.past-date {
  color: #d5d5d5;
}

.calendar_content div.today {
  font-weight: bold;
  color: #fff !important;
  position: relative;
  top: -5px;
}

.calendar_content div.today span {
  background-color: #60c1f1;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_content div.selected {
  background-color: #f0f0f0;
}

.calendar_header {
  width: 100%;
  text-align: center;
  border-bottom: 1px dashed #60c1f1;
  margin-bottom: 15px;
}

.calendar_header h2 {
  padding: 0 10px;
  font-family: "Muli", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #60c1f1;
  float: left;
  width: 70%;
  margin: 0 0 10px;
}

button.switch-month {
  background-color: transparent;
  padding: 0;
  outline: none;
  border: none;
  color: #dcdcdc;
  float: left;
  width: 15%;
  transition: color 0.2s;
}

button.switch-month:hover {
  color: #87b633;
}

.switch-left {
  text-align: left;
}

.switch-right {
  text-align: right;
}

.calendar_content span {
  width: 25px;
  height: 25px;
}

/*Calendar*/
.brdr-light-blue {
  border-color: #60c1f1 !important;
}

.calendar_weekdays>div {
  color: #60c1f1 !important;
}

.xtra-light-black-txt {
  opacity: 0.36;
}

.brdr-70 {
  border-color: #707070 !important;
}

.timeline-box .cstm-hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #e1dddd;
  height: 2px;
  margin-left: -8px;
}

.timeline-box .red-indicator {
  height: 1px;
  position: absolute;
  width: 75%;
  background-color: #ee135d;
  left: 22.5%;
  top: 10%;
  z-index: 1;
}

.timeline-box .red-indicator:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50px;
  display: inline-block;
  background-color: #ee135d;
  position: absolute;
  left: -2px;
  top: -2px;
}

.timeline-box .red-indicator:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  display: inline-block;
  background-color: #ee135d;
  position: absolute;
  right: -3px;
  top: -4px;
}

.brdr-2 {
  border-width: 2px !important;
}

.cstm-nav-tabs-sml .nav-link {
  font-family: poppins-regular;
  background-color: #f5f4f4;
}

.cstm-nav-tabs-sml .nav-link.active {
  padding: 0;
  border: 1px solid #4589d8;
  border-bottom: 0;
  height: 45px;
}

.cstm-nav-tabs-sml .nav-link.active>div {
  border-bottom: 2px solid #4589d8;
  color: #4589d8;
  font-weight: bold;
  margin-top: -12px;
  padding-top: 15px;
  padding-bottom: 11px;
  border-radius: 0;
  margin: 0 3px;
  padding-left: 30px;
  padding-right: 30px;
}

.brdr-blue-2 {
  border-color: #4589d8;
}

/*27-Nov-2020*/
/*03-Dec-2020*/
.bg-blue {
  background-color: #217dc3;
}

.bg-purple {
  background-color: #e61a80;
}

.fnt-1-25rem {
  font-size: 1.25rem;
}

.cstm-navbar {
  height: 60px;
}

.h-60 {
  height: 60px;
}

.cstm-navbar .dropdown-toggle::after {
  color: #fff;
}

section.main-content {
  width: 100%;
  box-sizing: border-box;
  float: left;
  /*padding-left: 35px;
padding-right: 35px;*/
  /*margin-left: 70px;*/
  padding-left: 70px;
  padding-right: 30px;
  transition: ease 0.2s;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
}

section.main-content.auto-adjust {
  width: 80%;
}

.wrapper {
  height: inherit;
}

.ml--30 {
  margin-left: -30px;
}

.mr--30 {
  margin-right: -30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.big-nav-tabs-2 {
  cursor: pointer;
  width: auto;
  float: left;
}

.big-nav-tabs-2 .nav-item {
  width: auto;
  margin-right: 20px;
}

.big-nav-tabs-2 .nav-link {
  color: #fff;
  border: none !important;
  border-bottom: 3px solid transparent !important;
  font-family: Roboto-bold;
  font-size: 0.8rem;
  padding: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 60px;
  background-color: transparent;
  margin-top: 0;
}

.big-nav-tabs-2 .nav-link.active {
  border: none !important;
  border-bottom: 3px solid #2680eb !important;
  border-radius: 0;
  background-color: transparent;
  color: #fff;
  font-weight: normal;
  font-size: 0.8rem;
  height: auto;
  // margin-top: 0;
  padding-top: 0px;
  font-family: Roboto-bold;
  padding: 0;
  padding-bottom: 10px;
}

.big-nav-tabs-2 .nav-link.active>span {
  background-color: #2680eb;
  border: 0.5px solid #2680eb;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  width: 100%;

}

.big-nav-tabs-2 .nav-link>span {
  margin-bottom: 1rem !important;
  width: 100%;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.big-nav-tabs-2 .nav-item.active {
  border-radius: 0;
  background-color: transparent;
}

.big-nav-tabs-2 .nav-item:nth-child(1) .nav-link.active>span {
  box-shadow: 0px 1px 2px #0b3261;
  -webkit-box-shadow: 0px 1px 2px #0b3261;
  -moz-box-shadow: 0px 1px 2px #0b3261;
}

.big-nav-tabs-2 .nav-item:nth-child(2) .nav-link.active>span {
  box-shadow: 0px 1px 2px #023704;
  -webkit-box-shadow: 0px 1px 2px #023704;
  -moz-box-shadow: 0px 1px 2px #023704;
}

.big-nav-tabs-2 .nav-item:nth-child(3) .nav-link.active>span {
  box-shadow: 0px 1px 2px #720013;
  -webkit-box-shadow: 0px 1px 2px #720013;
  -moz-box-shadow: 0px 1px 2px #720013;
}

.big-nav-tabs-2 .nav-item:nth-child(2) .nav-link.active {
  border-bottom: 3px solid #11c417 !important;
}

.big-nav-tabs-2 .nav-item:nth-child(3) .nav-link.active {
  border-bottom: 3px solid #c4112f !important;
}

.big-nav-tabs-2 .nav-item:nth-child(1) .nav-link>span {
  background-color: #2680eb;
  border: 0.5px solid #2680eb;
}

.big-nav-tabs-2 .nav-item:nth-child(2) .nav-link>span {
  background-color: #11c417;
  border: 0.5px solid #11c417;
}

.big-nav-tabs-2 .nav-item:nth-child(3) .nav-link>span {
  background-color: #c4112f;
}

.big-nav-tabs-2 .nav-item:nth-child(3) .nav-link.active>span {
  background-color: #c4112f;
  border: 0.5px solid #c4112f;
}

.big-nav-tabs-2 .nav-item:nth-child(3) .nav-link.active .live-calls-icon:after {
  display: none;
}

.big-nav-tabs-2 .nav-item:nth-child(3) .nav-link>span {
  background-color: #ebebeb;
}

.live-calls-icon {
  position: relative;
}

.live-calls-icon:after {
  content: "";
  width: 60px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  display: inline-block;
  top: 0;
  transform: rotate(120deg);
  top: 14px;
  left: -15px;
}

.nav-tabs-plain {
  border-color: #dbdbdb !important;
  width: auto;
  float: left;
}

.nav-tabs-plain li {
  margin-left: 25px;
  font-size: 0.8rem !important;
  font-family: Roboto-bold;
  color: #666666;
  cursor: pointer;
}

.nav-tabs-plain li:last-child {
  margin-right: 25px;
}

.nav-tabs-plain .nav-link {
  border: none;
}

.nav-tabs-plain .nav-link.active {
  border-bottom: 3px solid #2680eb;
}

.gray-circle-btns>span {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  background-color: #e2e2e2;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.gray-circle-btns>span img,
.gray-circle-btns>span svg {
  height: 15px;
}

.gray-circle-btns span.bg-blue {
  background-color: #2fa5df;
}

.custom-checkbox-rounded-square input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  left: -2px;
  border-color: white;
}

.custom-checkbox-rounded-square label {
  box-sizing: border-box;
  border: 1px solid #707070;
  padding: 5px;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 7px;
  color: transparent;
  width: 10px;
  height: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox-rounded-square label:hover {
  background-color: #fff;
  border: 1px solid #5fc9d8;
}

.custom-checkbox-rounded-square input[type="checkbox"]:checked+label {
  background-color: #d7fffb;
  color: #333;
  border: 1px solid #5fc9d8;
}

// .sml-blue-header thead th {
//   color: #2680eb;
//   font-size: 0.6rem !important;
//   text-transform: uppercase;
//   letter-spacing: 1px;
//   font-family: Roboto-bold;
//   border: none;
//   padding: 5px;
// }
// .sml-blue-header tbody td {
//   vertical-align: middle;
//   font-size: 0.7rem !important;
//   color: #666666;
//   border: none;
//   padding: 5px;
// }
// .sml-blue-header thead th:last-child {
//   text-align: center !important;
// }
.user-blue-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #27abec;
  width: 30px;
  height: 30px;
  color: #fff;
}

.tbl-row {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-bottom: 10px;
}

.icon-light-blue path,
.icon-light-blue g circle {
  fill: #27abec;
}

.status {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  padding: 3px 10px;
}

.status.complete {
  background-color: #c1f0c3 !important;
  color: #11c417 !important;
}

.status.none {
  background-color: #929292;
  color: #fff;
}

.error {
  color: #ef4646 !important;
  font-size: 12px;
}

.status.danger {
  background-color: #f0c1c9 !important;
  color: #c4112f !important;
}

.sidebar {
  width: 70px;
  height: inherit;
  float: left;
  background-color: #eeeeee;
  margin-top: 2px;
  position: fixed;
  max-height: inherit;
  overflow-y: auto;
  overflow-x: hidden;

  transition: ease 0.2s;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
}

.sidebar.active {
  width: 235px;
  display: block;
  transition: ease 0.2s;
  -webkit-transition: ease 0.2s;
  -moz-transition: ease 0.2s;
}

.sidebar>ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.sidebar>ul>li:last-child .dropdown-menu-2020 {
  margin-bottom: 100px;
}

.sidebar>ul>li {
  min-height: 69px;
  list-style: none;
  border-bottom: 1px solid #ccc;
  width: 235px !important;
  overflow: hidden;
  font-size: 0.8rem;
}

.sidebar>ul>li:hover {
  color: #40aefd;
}

.sidebar>ul>li>a {
  box-sizing: border-box;
  padding: 15px;
  padding-left: 22px;
  float: left;
  width: 100%;
  color: #666;
  display: inline-flex;
  align-items: center;
  text-align: center;
}

.sidebar>ul>li>a:hover {
  color: #40aefd;
}

.sidebar>ul>li>a .light-blue {
  fill: #a7a7a7;
}

.sidebar>ul>li:hover {
  background-color: #f5f5f5;
}

.sidebar>ul>li.active {
  background-color: #f5f5f5;
}

.sidebar>ul>li.active a {
  color: #40aefd;
}

.sidebar>ul>li.active a .light-blue {
  fill: #40aefd !important;
}

.sidebar>ul>li span {
  margin-right: 10px;
}

.sidebar>ul>li span.icon {
  padding: 5px;
  float: left;
  background-position: center center;
  width: 27px;
  height: 27px;
  display: inline-block;
  line-height: 14px;
  vertical-align: text-top;
  background-repeat: no-repeat;
}

.sidebar>ul>li:hover span.title {
  color: #40aefd;
}

.sidebar>ul>li span.workoholic-icon {
  // background-image: url(./assets/icons/workoholic-icon-gray.png);
}

.sidebar>ul>li.active span.workoholic-icon {
  // background-image: url(./assets/icons/workoholic-icon-blue.png);
}

.sidebar>ul>li:hover span.workoholic-icon {
  // background-image: url(./assets/icons/workoholic-icon-blue.png);
}

.sidebar>ul>li span.title {
  float: left;
  padding: 10px 0px;
  opacity: 0;
  margin-left: 20px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

.sidebar.active>ul>li span.title {
  display: block;
  margin-left: 0;
  opacity: 10;
}

.sidebar ul>.dropdown>a:after {
  content: "";
  width: 8px;
  height: 10px;
  float: right;
  //  background-image: url(./assets/icons/arrow-right-icon.png);
  background-repeat: no-repeat;
  background-position: top right;
  top: 13px;
  right: 10px;
  position: relative;
  transform: rotate(-30deg);
}

.sidebar ul>.dropdown>a.active:after {
  content: "";
  width: 8px;
  height: 10px;
  float: right;
  // background-image: url(./assets/icons/arrow-right-icon.png);
  background-repeat: no-repeat;
  background-position: top right;
  top: 13px;
  right: 10px;
  position: relative;
  transform: rotate(30deg);
}

/*.ml240 {
  margin-left: 240px !important;
}*/
.ml240 {
  padding-left: 235px !important;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.cstm-navbar .dropdown-menu {
  font-size: 0.7rem;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  border: none;
}

.cstm-navbar .dropdown-menu:before {
  content: "";
  position: absolute;
  right: 20px;
  top: -8px;
  width: 8px;
  height: 8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
}

.cstm-navbar .dropdown-menu .dropdown-item:nth-child(even) {
  background-color: rgba(226, 226, 226, 0.17);
}

.cstm-navbar .dropdown-menu .dropdown-item:nth-child(odd) {
  background-color: rgba(226, 226, 226, 1);
}

.cstm-navbar .dropdown-menu .dropdown-item {
  background-color: #2fa5df;
  color: #666666;
  text-transform: uppercase;
  font-family: Roboto-BoldCondensed;
}

.cstm-navbar .dropdown-menu .dropdown-item:hover {
  background-color: #2fa5df;
  color: #fff;
}

.red-close {
  background-color: #c4112f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.bg-f5 {
  background-color: #f5f5f5;
}

.brdr-e2 {
  border-color: #e2e2e2;
}

.cstmr-dtls-popup-box {
  font-family: Roboto-Condensed;
}

.txt-92 {
  color: #929292;
}

.txt-blue-dull {
  color: #217dc3;
}

#cstmrDtls .modal-dialog {
  max-width: 700px;
}

.dahsed-h-line {
  width: auto;
  height: 1px;
  border-bottom: 1px dashed #c7c5c5;
}

.bg-e2 {
  background-color: #e2e2e2;
}

.txt-c7 {
  color: #c7c5c5;
}

.schedular-popup-box h6 {
  font-family: poppins-Medium;
}

.schedular-popup-box:before {
  content: "";
  position: absolute;
  right: -6px;
  top: 10px;
  width: 6px;
  height: 6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
}

.transfer-box:before {
  content: "";
  position: absolute;
  right: -6px;
  top: 10px;
  width: 6px;
  height: 6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
}

.transfer-box,
.menu-box {
  box-shadow: 0px 3px 6px #00000029;
}

.transfer .dropdown-menu,
.menu-icon .dropdown {
  font-size: 0.7rem;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  border: none;
}

.transfer .dropdown-menu .dropdown-item:nth-child(odd),
.menu-icon .dropdown-menu .dropdown-item:nth-child(odd) {
  background-color: rgba(226, 226, 226, 0.17);
}

.transfer .dropdown-menu .dropdown-item:nth-child(even),
.menu-icon .dropdown-menu .dropdown-item:nth-child(even) {
  background-color: rgba(226, 226, 226, 1);
}

.transfer .dropdown-menu .dropdown-item {
  background-color: #2fa5df;
  color: #666666;
  font-family: Roboto-regular;
  padding-left: 10px;
}

.transfer .dropdown-menu .dropdown-item:hover,
.menu-icon .dropdown-menu .dropdown-item:hover {
  background-color: #2fa5df;
  color: #fff;
}

.transfer .dropdown-menu .dropdown-item>span {
  padding: 10px;
}

.call-white-icon path {
  fill: #fff;
}

.menu-icon .dropdown-menu .dropdown-item {
  background-color: #2fa5df;
  color: #666666;
  font-family: Roboto-CondensedItalic;
  padding-left: 5px;
  display: inline-flex;
  font-style: italic;
}

.menu-icon .dropdown-menu .dropdown-item>span {
  padding: 0;
}

.menu-icon .dropdown-menu .dropdown-item>span:first-child {
  margin-right: 5px;
}

.menu-icon .dropdown-menu .dropdown-item:hover .reschedule-call-icon svg path {
  fill: #fff;
}

.reschedule-call-icon svg {
  width: 13px;
}

.back-assign-icon svg {
  width: 13px;
}

.transfer-menu-icon svg {
  width: 13px;
}

.back-to-call-icon svg {
  width: 13px;
}

.menu-icon .dropdown-menu .dropdown-item:hover .reschedule-call-icon svg path,
.menu-icon .dropdown-menu .dropdown-item:hover .back-to-call-icon svg path,
.menu-icon .dropdown-menu .dropdown-item:hover .transfer-menu-icon svg path,
.menu-icon .dropdown-menu .dropdown-item:hover .back-assign-icon svg path {
  fill: #fff;
}

.back-to-call-icon svg path {
  fill: #666;
}

.right-sidebar {
  background-color: #fbfbfb;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #eeeeee;
  padding-left: 30px;
  position: fixed;
  top: 60px;
  right: 0;
  height: 100%;
}

.right-sidebar .calendar-icon {
  background-color: #fbfbfb;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 20px;
  padding: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.right-sidebar .calendar-icon img {
  width: 23px;
}

.right-sidebar .right-sidebar-inner {
  position: relative;
  background-color: #fff;
  border: 1px solid #f0f0f0;
}

.right-sidebar.active {
  width: 320px;
  padding-left: 0;
  padding: 10px;
  padding-top: 0;
}

.right-sidebar.active .calendar-icon {
  right: 280px;
  z-index: 3;
}

.sidebar-calendar {
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  position: relative;
  height: 110px;
  overflow: hidden;
}

.sidebar-calendar .calendar_header {
  border-bottom: 0;
  width: 160px;
  margin-left: 15px;
  display: inline-flex;
  align-items: center;
}

.sidebar-calendar .calendar_header h2 {
  text-transform: capitalize !important;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  color: #707070 !important;
  font-family: Roboto-BoldCondensed;
}

.sidebar-calendar .calendar_weekdays>div {
  color: #707070 !important;
}

.right-sidebar .timeline-box .col-md-3 {
  font-size: 0.6rem;
  padding: 0px 5px;
}

.right-sidebar .timeline-box .cstm-hr {
  height: 1px;
}

.bg-purple {
  background-color: #ef35a5;
}

.the-event-name {
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
}

.events {
  height: 1px;
  position: absolute;
  width: 75%;
  left: 22.5%;
  top: -2%;
  z-index: 1;
  margin-bottom: 2px;
}

.events.big .the-event-name {
  padding: 5px 10px;
}

.bg-light-purple {
  background-color: #d635ef;
}

.cstm-arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid #fff;
}

.status .dropdown-menu:before {
  content: "";
  display: none;
}

.status.dropdown-toggle:after {
  display: none;
}

/*07-Dec-2020*/
/*08-Dec-2020*/
.new-sticker-positioned {
  position: absolute;
  left: -30px;
  top: 22px;
}

#cstmrDtls-2 .modal-dialog {
  max-width: 700px;
}

.custom-dropdown-box {
  box-sizing: border-box;
  font-family: arial, sans-serif;
  width: 200px;
  font-size: 14px;
  min-height: 25px;
  width: 100%;
}

.custom-dropdown-box .caret {
  float: none;
}

.custom-dropdown-box .custom-select-drp {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 20px !important;
  justify-content: space-between;
}

.custom-dropdown-box .custom-dropdown-menu {
  margin: 0;
  padding-left: 0;
  cursor: pointer;
  background-color: white;
  display: none;
  padding: 10px;
  padding-bottom: 15px;
  padding-top: 0;
  border-bottom-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  left: 0px;
  background-color: #217dc3;
}

.custom-dropdown-menu>li {
  list-style: none;
  padding: 3px 10px;
  color: #666666;
  text-align: center;
  color: #666;
  font-size: 12px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
}

.custom-dropdown-menu>li:hover {
  opacity: 0.8;
  color: #333;
}

.custom-dropdown-menu>li:last-child {
  border: none;
}

.rounded-btm-0 {
  border-bottom-left-radius: 0px !important;
  -webkit-border-bottom-left-radius: 0px !important;
  -moz-border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  -webkit-border-bottom-right-radius: 0px !important;
  -moz-border-bottom-right-radius: 0px !important;
  border-top-right-radius: 4px !important;
  -webkit-border-top-right-radius: 4px !important;
  -moz-border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  -webkit-border-top-left-radius: 4px !important;
  -moz-border-top-left-radius: 4px !important;
}

.brdr-trnsprnt {
  border-color: transparent !important;
}

.prtcpnt-drpdn li>span {
  font-family: Roboto-Condensed;
}

.prtcpnt-drpdn li>span:last-child {
  font-family: Roboto-BoldCondensed;
}

.dropdown-menu.notification-dropdown {
  right: 0 !important;
  left: auto !important;
  padding: 0;
  border: 1px solid #217dc3;
}

.notification-dropdown .dropdown-item:first-child {
  padding: 10px;
  font-family: Roboto-regular !important;
  font-size: 0.9rem;
  border-bottom: 1px solid #217dc3;
}

.notification-dropdown .dropdown-item {
  font-size: 0.8rem;
  padding: 5px 10px;
  border-bottom: 1px dashed #ccc;
  font-family: Roboto-CondensedItalic !important;
  color: #217dc3 !important;
  background-color: #fff;
  text-transform: capitalize !important;
  display: flex;
  align-items: center;
}

.notification-dropdown .dropdown-item:hover {
  color: #217dc3 !important;
}

.notification-dropdown .dropdown-item:nth-child(odd) {
  background-color: #fff !important;
}

.notification-dropdown .dropdown-item:nth-child(even) {
  background-color: #fff !important;
}

.icon-blue-dark path,
.icon-blue-dark circle {
  fill: #217dc3;
}

.notification-dropdown .dropdown-item.disabled .icon-blue-dark path,
.notification-dropdown .dropdown-item.disabled .icon-blue-dark circle,
.notification-dropdown .dropdown-item.disabled {
  fill: #666;
  color: #666 !important;
}

.dropdown-notification .dropdown-toggle:after {
  content: "";
  display: none !important;
}

.notification-dropdown .dropdown-item:last-child {
  border-bottom: 0;
}

.bld-cndsd {
  font-family: Roboto-BoldCondensed;
}

.brdr-blue-dull {
  border-color: #217dc3 !important;
}

.call-sec {
  background-color: #dbdbdb2e;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  padding: 10px 20px;
  position: relative;
}

.mt-150 {
  margin-top: 150px;
}

.user-blue-circle-big {
  width: 40px;
  height: 40px;
}

.call-sec .user-blue-circle-big {
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: 500;
}

@keyframes example {
  0% {
    background-color: red;
    top: -120px;
  }

  25% {
    background-color: yellow;
    top: -60px;
  }

  50% {
    background-color: #217dc3;
    top: -90px;
  }

  100% {
    background-color: red;
    top: -30px;
  }
}

.custom-radio-tabular input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  left: -2px;
  border-color: white;
}

.custom-radio-tabular label {
  border: 1px solid #ccc;
  margin-right: 5px;
  padding: 5px 15px;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  cursor: pointer;
  margin-bottom: 0;
}

.custom-radio-tabular label:hover {
  background-color: #fff;
  border: 1px solid #5fc9d8;
}

.custom-radio-tabular input[type="radio"]:checked+label {
  background-color: #d7fffb;
  color: #333;
  border: 1px solid #5fc9d8;
}

.nav-tabs-plain-2 {
  border-color: #dbdbdb !important;
  width: auto;
  float: left;
  border: none;
}

.nav-tabs-plain-2 li {
  font-size: 0.8rem !important;
  font-family: Roboto-bold;
  color: #666666;
  cursor: pointer;
}

.nav-tabs-plain-2 li:last-child {
  margin-right: 25px;
}

.nav-tabs-plain-2 .nav-link {
  border: none;
  padding: 12px 48px;
  border-top-left-radius: 15px;
  -webkit-border-top-left-radius: 15px;
  -moz-border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-top-right-radius: 15px;
  border: 1px solid #dbdbdb;
  border-bottom: 0;
  font-family: poppins-Medium;
  color: #c8c8c8;
}

.nav-tabs-plain-2 .nav-link.active {
  border-bottom: 0px solid #dbdbdb;
  color: #2fa5df;
}

thead.poppins-rglr th {
  font-family: poppins-regular;
}

thead.txt-6B th {
  color: #6b6b6b;
  text-transform: capitalize !important;
}

.txt-92 {
  color: #929292 !important;
}

.bg-db {
  background-color: #dbdbdb;
}

.cstm-arrow-down.light-blue {
  border-top: 5px solid #2fa5df;
}

.btn-reject {
  background-color: #fed0d0;
  color: #e61616;
  border: 2px solid #ff7070;
  min-width: 90px;
}

.btn-re-assign {
  background-color: #ffe9c8;
  color: #fc9c0d;
  border: 2px solid #fc9c0d;
  min-width: 90px;
}

.btn-approve {
  background-color: #a6fca6;
  color: #30ac30;
  border: 2px solid #30ac30;
  min-width: 90px;
}

.btn-reject:hover,
.btn-re-assign:hover,
.btn-approve:hover {
  opacity: 0.8;
}

.txt-9a {
  color: #9a9a9a;
}

.poppins-bld {
  font-family: poppins-bold;
}

.poppins-medium {
  font-family: poppins-Medium;
}

.bg-fc {
  background-color: #fcfcfc;
}

.brdr-dashed {
  border-style: dashed !important;
}

.cstm-navbar-2 .dropdown-toggle::after {
  color: #60c1f1;
}

.form-tab {
  background-color: #60c1f1;
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: poppins-Medium;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.bg-f3 {
  background-color: #f3f3f3;
}

.txt-b1 {
  color: #b1b1b1;
}

.form-control-sml .form-control {
  height: 30px;
  margin-bottom: 10px;
}

.btn-light-blue {
  background-color: #60c1f1;
  color: #fff;
  padding: 8px 20px;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.btn-light-blue:hover {
  opacity: 0.8;
}

.btn-light-blue-secondary {
  background-color: #fff;
  border: 1px solid #60c1f1;
  color: #60c1f1;
  text-align: center;
  padding: 8px 20px;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.btn-light-blue-secondary:hover {
  opacity: 0.8;
}

.btn-light-blue-secondary.sm {
  padding: 3px 10px;
  font-size: 0.7rem !important;
}

.search-light-blue {
  border: 1px solid #60c1f1;
  background-image: url(./assets/icons/search.svg);
  background-repeat: no-repeat;
  background-size: 5%;
  background-position: left 10px top 10px;
  padding-left: 40px;
  font-family: poppins-italic;
  font-size: 0.8rem;
}

.search-light-blue::placeholder {
  color: #60c1f1;
  opacity: 0.8;
}

.poppins-rglr {
  font-family: poppins-regular;
}

thead.bg-91 th {
  background-color: #ccc !important;
}

.white-th-txt th {
  color: #fff !important;
}

.fnt-nrml {
  font-weight: normal;
}

.white-checkbox label {
  border-color: #dedede;
  background-color: #fff;
}

.top-rounded th:first-child {
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-top-left-radius: 4px;
}

.top-rounded th:last-child {
  border-top-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-top-right-radius: 4px;
}

.sml-gray-header thead th {
  color: #2680eb;
  font-size: 0.7rem !important;
  letter-spacing: 1px;
  font-weight: normal;
  font-family: poppins-Medium;
  border: none;
  padding: 5px;
}

.sml-gray-header tbody td {
  vertical-align: middle;
  font-size: 0.7rem !important;
  color: #666666;
  border: none;
  padding: 5px;
}

.sml-gray-header thead th:last-child {
  text-align: center !important;
}

.btn-light-orange-secondary.sm {
  border: 1px solid #ffa33a;
  padding: 3px 10px;
  color: #ffa33a;
  background-color: #fff;
  font-size: 0.7rem !important;
}

.btn-light-orange-secondary.sm:hover {
  opacity: 0.8;
}

.thead-v-mdl th {
  vertical-align: middle !important;
}

.custom-dropdown-box.light-blue-theme {
  box-sizing: border-box;
  font-family: arial, sans-serif;
  width: 100%;
  font-size: 14px;
  min-height: 25px;
  width: 100%;
}

.custom-dropdown-box.light-blue-theme .caret {
  float: none;
}

.custom-dropdown-box.light-blue-theme .cstm-select {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: 1px solid #60c1f1;
}

.custom-dropdown-box.light-blue-theme.active .cstm-select {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.custom-dropdown-box.light-blue-theme .custom-dropdown-menu {
  margin: 0;
  padding-left: 0;
  z-index: 1;
  cursor: pointer;
  display: none;
  padding: 0;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  left: 0px;
  background-color: transparent;
  border: 1px solid #60c1f1;
  border-top: 0;
}

.light-blue-theme .custom-dropdown-menu>li {
  list-style: none;
  padding: 8px 10px;
  padding-left: 37px;
  text-align: center;
  color: #9a9a9a;
  font-size: 0.7rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #60c1f1;
  box-shadow: none;
  margin-bottom: 0;
}

.light-blue-theme .custom-dropdown-menu>li:first-child {
  border-top: 0;
}

.light-blue-theme .custom-dropdown-menu>li:last-child {
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-bottom-right-radius: 4px;
}

.light-blue-theme .custom-dropdown-menu>li:hover {
  opacity: 9;
  color: #333;
}

.bg-yellow {
  background-color: #ffb03a;
}

.bg-light-green {
  background-color: #60f190;
}

.bg-purple-2 {
  background-color: #c41cbe;
}

.bg-orange-2 {
  background-color: #ff693a;
}

.roboto-bld-cndsd {
  font-family: Roboto-BoldCondensed;
}

.auditor-dashboard-panels {
  box-shadow: 0px 2px 4px #70707078;
  -webkit-box-shadow: 0px 2px 4px #70707078;
  -moz-box-shadow: 0px 2px 4px #70707078;
}

.col-20-p {
  width: 20%;
}

.auditor-dashboard .white-chart-box {
  box-shadow: 0px 1px 2px #00000029;
  -webkit-box-shadow: 0px 1px 2px #00000029;
  -moz-box-shadow: 0px 1px 2px #00000029;
  border-color: #d4d4d4 !important;
}

.auditor-dashboard .white-chart-box .header {
  border-bottom: 1px solid #b1b1b1;
  padding: 10px;
}

.auditor-dashboard .white-chart-box .header span:first-child {
  padding-left: 15px;
  font-family: poppins-Medium;
}

.auditor-dashboard .white-chart-box .header .col:first-child>span:first-child:before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #60c1f1;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  position: absolute;
  top: 5px;
  left: 2px;
}

.auditor-dashboard .white-chart-box .header .col:first-child>span.orange:first-child:before {
  background-color: #ff693a;
}

.cstm-arrow-down.light-blue-2 {
  border-top: 5px solid #60c1f1;
}

.cstm-arrow-down.orange {
  border-top: 5px solid #ff693a;
}

.auditor-dashboard .gray-chart-box {
  background-color: #f3f3f3;
  border-color: #b1b1b15c !important;
  padding: 20px;
}

.txt-orange-2 {
  color: #ff693a;
}

.custom-dropdown-box.light-blue-theme.gray-brdr .cstm-select {
  border-color: #b1b1b1 !important;
}

.custom-dropdown-box.light-blue-theme.gray-brdr .custom-dropdown-menu {
  border-color: #b1b1b1 !important;
}

.light-blue-theme.gray-brdr .custom-dropdown-menu>li {
  border-color: #b1b1b1 !important;
}

.light-blue-theme.gray-brdr .custom-dropdown-menu>li:hover {
  background-color: #60c1f1;
  color: #fff;
}

.cstm-arrow-down.gray {
  border-top: 5px solid #dedede;
}

.filter-panel.active {
  position: relative;
}

.filter-panel.active .filter-btn:before {
  position: absolute;
  z-index: 2;
  top: -10px;
  left: -8px;
  padding: 5px;
  content: "";
  border: 1px solid #60c1f1;
  border-bottom: 1px solid #fff !important;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  width: 170px;
  height: 48px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.filter-panel.active .filter-btn {
  background-color: #dedede;
}

.filter-panel.active .filter-btn:hover {
  opacity: 9;
}

.filter-panel.active .filter-btn:focus {
  box-shadow: none;
}

.filter-panel .inner {
  border: 1px solid #60c1f1;
  position: absolute;
  left: -8px;
  padding: 10px 5px 10px 0px;
  z-index: 1;
  width: 320px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-top-right-radius: 4px;
}

.filter-panel .inner input {
  max-width: 107px;
}

.filter-panel .inner .form-control,
.filter-panel .inner .btn {
  margin-left: 5px;
}

.filter-panel .inner .form-control {
  border-color: #b1b1b1;
  padding-left: 25px;
}

.filter-panel .inner .form-control::placeholder {
  color: #60c1f1;
}

// .has-calendar-icon {
//   background-image: url(../img/month-icon.svg);
//   background-repeat: no-repeat;
//   background-position: top 10px left 7px;
// }

.scrollbale-box {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.notes-textarea:focus {
  box-shadow: none;
}

.card-header .btn-link svg {
  position: absolute;
  right: 10px;
  top: 13px;
}

.card-header .btn-link.collapsed svg {
  transform: rotate(180deg);
}

.radius-20 {
  border-radius: 20px;
}

.carousel-item {
  min-height: 380px;
}

/*17-Dec-2020*/
.notification-container {
  width: 25em;
  height: 25em;
  padding: 1em;
}

.schedule-button {
  height: 25px;
  float: right;
  margin-top: -5px;
  background-color: #60c1f1;
  border: 1px solid #27abec;
  color: white;
  border-radius: 2px;
}

/*Dheeraj Verma 13-Jan-2021*/
.white-input {
  border: 1px solid #fff;
  padding: 8px 15px;
  background-color: #fff;
  font-size: 0.8rem;
  color: #0f77c7 !important;
  letter-spacing: 1px;
  position: relative;
}

.circle-tick-icon {
  cursor: pointer;
  background-image: url(./assets/icons/circle-tick-icon-gray.png);
  background-position: left -1px top 4px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.active .circle-tick-icon {
  cursor: pointer;
  background-image: url(./assets/icons/circle-tick-icon-blue.png);
}

/*Dheeraj Verma 13-Jan-2021*/

//25-jan
.nav-tabs-plain.transparent {
  border-bottom: 0 !important;
}

.nav-tabs-plain.transparent .nav-link.active {
  background-color: transparent;
  color: #2680eb;
}

.btn-dark-blue-secondary {
  background-color: #fff;
  border: 1px solid #217dc3;
  color: #217dc3;
  text-align: center;
  padding: 8px 20px;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.btn-dark-blue-secondary:hover {
  opacity: 0.8;
}

.sml-blue-bg-header thead th {
  color: #fff;
  background-color: #2680eb;
  font-size: 0.6rem !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Roboto-bold;
  border: none;
  padding: 5px 6px;
}

.sml-blue-bg-header thead tr:first-child th:first-child {
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-top-left-radius: 4px;
}

.sml-blue-bg-header thead tr:first-child th:last-child {
  border-top-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-top-right-radius: 4px;
}

.sml-blue-bg-header tbody td {
  vertical-align: middle;
  font-size: 0.7rem !important;
  color: #666666;
  border-color: #efefef;
  padding: 0px 5px;
}

.gray-circle-btns.bg-trns-menu-blue>span {
  background-color: transparent;
}

.gray-circle-btns.bg-trns-menu-blue>span img {
  height: auto;
}

.gray-circle-btns.no-span span {
  width: unset;
  height: unset;
  padding: 0;
}

.t-d-none:hover {
  text-decoration: none;
}

.type {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  padding: 3px 10px;
}

.type.none {
  background-color: #929292;
  color: #fff;
}

.agent-select {
  background-color: #bdd9f9;
  color: #2680eb;
}

.auditor-select {
  background-color: #edddb0;
  color: #ebc55c;
}

.both-select {
  background-color: #b7edb9;
  color: #11c417;
}

.hover-ef-bg:hover {
  background-color: #efefef !important;
}

.add-exe-form {
  border: 1px solid #2680eb;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 25px;
  position: relative;
  margin-bottom: 15px;
}

.close-icon {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 5px;
  right: 5px;
  background-image: url(./assets/icons/close-icon.png);
  background-repeat: no-repeat;
  cursor: pointer;
}

.add-exe-form .heading {
  color: #2680eb;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: Roboto-BoldCondensed;
}

.add-exe-form select {
  font-size: 14px;
  height: 35px;
}

.add-exe-form label {
  color: #217dc3;
  font-size: 13px;
  font-family: Roboto-regular;
  margin-bottom: 0;
}

.add-exe-form .form-control {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border: 1px solid #217dc3;
  padding: 7px 5px !important;
  font-size: 12px;
}

.add-exe-form input[type="text"]::placeholder {
  font-size: 12px;
  font-family: Roboto-italic;
  color: #217dc3;
  opacity: 0.4;
  letter-spacing: 0.5px;
  padding-left: 5px;
}

.add-exe-form .form-group {
  margin-bottom: 0.3rem;
}

.add-exe-form .primary-btn {
  background-color: #217dc3;
  text-transform: uppercase;
  color: #fff;
  height: 40px;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  font-family: Roboto-BoldCondensed;
  letter-spacing: 1px;
}

.add-exe-form .primary-btn:disabled {
  opacity: 0.57;
}

.tooltip {
  font-size: 11px;
}

.circle-bg-icons li svg {
  width: 18px;
}

.circle-bg-icons li.active path,
.circle-bg-icons li.active circle {
  fill: #fff;
}

.circle-bg-icons li.active a span:first-child {
  background-color: #2680eb;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  height: 30px;
  width: 30px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.header-2 .top-right {
  color: #2680eb;
}

.header-2 .top-right .clock-box {
  background-color: #ececec;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.bg-fb {
  background-color: #fbfbfb;
}

.txt-blue-2 {
  color: #2680eb;
}

.roboto-medium {
  font-family: Roboto-Medium;
}

.bg-f0 {
  background-color: #f0f5fc;
}

.bg-fc {
  background-color: #fcfcfc;
}

.blue-chart-circle {
  background-color: #217dc3;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  display: inline-block;
}

.green-chart-circle {
  background-color: #11c417;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  display: inline-block;
}

.red-chart-circle {
  background-color: #fb175d;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  display: inline-block;
}

.border-bottom-dashed-gray {
  border-bottom: 1px dashed #dee2e6;
}

.change-password-form label {
  color: #217dc3;
  text-transform: uppercase;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-family: Roboto-bold;
}

.change-password-form .form-control {
  border-color: #217dc3;
}

.change-password-form .input-group-prepend>span {
  border-color: #217dc3;
}

.change-password-form .primary-btn {
  background-color: #2580eb;
  border-color: #4b78af;
  text-transform: uppercase;
  font-size: 0.6rem;
  color: #fff;
  font-family: Roboto-bold;
  letter-spacing: 1px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.change-password-form .primary-btn:hover {
  opacity: 0.9;
}

.roboto-italic {
  font-family: Roboto-italic;
}

.change-password-form .otp-box-2 input {
  background: #ffffff;
  border: 1px solid #217dc3;
  float: left;
  margin: 3px;
  padding: 0.25rem 0.5rem;
}

/* Privacy Policy Page Css Starts Here*/
.page-bg-img {
  background: url(./assets/images/bg-img.png) top fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.overlap-box {
  height: auto;
  overflow: hidden;
  padding: 20px;
  // box-shadow: 0 0px 20px 0 rgb(0 0 0 / 20%);
  float: left;
  width: 100%;
  background: #fff;
  position: relative;
  margin-bottom: 100px;
}

.overlap-box:before {
  position: absolute;
  content: "";
  top: -50px;
  left: -50px;
  width: 150px;
  height: 150px;
  background: #5eb9ff;
  border-radius: 50%;
  border: 30px solid #5eb9ff;
}

.overlap-box:after {
  position: absolute;
  content: "";
  bottom: -50px;
  right: -100px;
  width: 400px;
  height: 400px;
  background: linear-gradient(to top left, #162135, #3d4a64);
  border-radius: 50%;
}

.inner-box {
  width: 100%;
  background: #fff;
  // box-shadow: 10px 0px 10px -10px rgb(0 0 0 / 10%);
  z-index: 2;
  padding: 0 30px 20px 30px;
  float: left;
  height: 100%;
  position: relative;
}

.heading-section .heading {
  font-family: "Roboto", sans-serif;
  color: #3d4a64;
  font-size: 24px;
  margin: 0 auto 25px auto;
  line-height: 30px;
  display: inline-block;
  position: relative;
  font-weight: 200;
}

.heading-section .heading:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  background: linear-gradient(to right, #3d4a64, #5ec9d9);
  bottom: -10px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.heading-section .version {
  font-size: 11px;
  color: #999;
  letter-spacing: 0.3px;
  font-family: "Roboto", sans-serif;
  display: block;
  text-align: center;
  font-weight: 300;
}

.privacy_policy_cntr .card {
  border: 0;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0;
}

.privacy_policy_cntr .card:first-child {
  border-top: 1px solid #ddd;
}

.privacy_policy_cntr .card-header {
  padding: 0;
  border-radius: 0;
  background-color: #fff;
  border-bottom: 0;
}

.privacy_policy_cntr .card-header button {
  border: 0;
  padding: 0.75rem 3rem 0.75rem 0;
  letter-spacing: 0.5px;
  text-decoration: none;
  font-size: 14px;
  position: relative;
}

.privacy_policy_cntr .card-header button:hover {
  color: #007bff;
}

.privacy_policy_cntr .card-header button:before {
  content: "\f068";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 0;
  color: #ccc;
  top: 16px;
}

.privacy_policy_cntr .card-header button.collapsed:before {
  content: "\f067";
  color: #007bff;
}

.privacy_policy_cntr .card-body {
  padding: 0.5rem 0 1rem 0;
}

.privacy_policy_cntr .card-body p,
.privacy_policy_cntr .card-body ul li {
  font-size: 14px;
  text-align: justify;
  letter-spacing: 0.3px;
  color: #777;
}

/* Privacy Policy Page Css Ends Here*/

.aadhaar-no-box-2 input,
.aadhaar-no-box-2 .input-group-append {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
}

.aadhaar-no-box-2 .input-group-text {
  background-color: #fff;
  border: none;
}

.note-icon-email {
  word-break: break-all;
  // border: 1px solid #60c1f1;
  padding: 2px;
}

.mobile-number {
  // border: 1px solid #60c1f1;
  padding: 2px;
}

// add participants
.add-user-form label {
  border-bottom: 1px dashed #e8e8e8;
  display: block;
  width: 100%;
  line-height: 38px;
}

.add-user-form .form-group {
  margin-bottom: 5px;
}

.add-user-form button:disabled {
  opacity: 0.5;
}

.add-user-form button {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px !important;
  padding-right: 25px !important;
  font-size: 0.8rem !important;
}

.add-user-form-buttons .add-btn {
  display: inline-flex;
}

.add-user-form-buttons .remove-btn {
  border-color: #c4112f;
  color: #c4112f;
  display: inline-flex;
}

.add-user-form {
  background-color: #fff;
  border-radius: 4px;
}

.enlarge {
  position: fixed;
  /* top: 50%; */
  z-index: 1;
  left: 25%;
  width: 15em;
  height: 15em;
}

.doc-enlarge {
  position: fixed;
  top: 9%;
  z-index: 9999999;
  left: 25%;
  width: 30em;
  max-height: 90vh;
}

@media only screen and (max-width: 480px) {
  .btn-custom {
    font-size: 0.7rem;
  }
}

#cstmrDtls-19-apr .modal-dialog {
  max-width: 1320px;
}

.sml-black-bg-header thead th {
  color: #fff;
  background-color: #666;
  font-size: 0.6rem !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Roboto-bold;
  border: none;
  padding: 5px 6px;
}

.sml-black-bg-header thead tr:first-child th:first-child {
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-top-left-radius: 4px;
}

.sml-black-bg-header thead tr:first-child th:last-child {
  border-top-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-top-right-radius: 4px;
}

.sml-black-bg-header tbody td {
  vertical-align: middle;
  font-size: 0.7rem !important;
  color: #666666;
  background-color: #fff;
  border-color: #fff;
  padding: 0px 5px;
}

.dropdown.bordered {
  background-color: transparent;
  border: 1px solid #666;
  color: #666;
}

.dropdown.bordered .cstm-arrow-down {
  border-top: 5px solid #666;
}

.arrow-acc {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-image: url(./assets/icons/arrow-down-acc-blue.png);
  background-repeat: no-repeat;
  background-position: left top;
  cursor: pointer;
}

.arrow-acc.active {
  background-image: url(./assets/icons/arrow-down-acc-gray.png);
}

.relative-acc {
  display: none;
}

.relative-acc.active {
  display: table-row;
}

.relative-acc td {
  background-color: #fbfbfb !important;
  border: unset;
}

.add-user-form label {
  border-bottom: 1px dashed #e8e8e8;
  display: block;
  width: 100%;
}

.add-user-form .form-group {
  margin-bottom: 5px;
}

.add-user-form button:disabled {
  opacity: 0.5;
}

.add-user-form button {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px !important;
  padding-right: 25px !important;
  font-size: 0.8rem !important;
}

.add-user-form-buttons .add-btn {
  display: inline-flex;
}

.add-user-form-buttons .remove-btn {
  border-color: #c4112f;
  color: #c4112f;
  display: inline-flex;
}

.qr-view-box {
  background: url(./assets/icons/qr-top-left-corner.png) no-repeat top left,
    url(./assets/icons/qr-top-right-corner.png) no-repeat top right,
    url(./assets/icons/qr-bottom-left-corner.png) no-repeat bottom left,
    url(./assets/icons/qr-bottom-right-corner.png) no-repeat bottom right;
  top: 100px;
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto;
}

.qr-view {
  background-color: transparent;
  background-image: linear-gradient(rgba(29, 138, 222, 0.6) 30%,
      rgba(255, 255, 255, 0.3) 60%);
  width: 180px;
  height: 180px;
  margin: 0 auto;
  position: relative;
}

.qr-view-side-icons {
  position: absolute;
  top: 0;
  right: -60px;
  width: 50px;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.qr-view-caption {
  border-radius: 17px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  background-color: #fff;
  position: absolute;
  top: 250px;
  left: 10px;
  color: #999;
  font-size: 0.75rem;
  text-align: center;
  padding: 1px 15px;
  line-height: 17px;
}

.qr-screen-footer {
  background-color: #fff;
  border-top-left-radius: 15px;
  -webkit-border-top-left-radius: 15px;
  -moz-border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-top-right-radius: 15px;
  padding: 0px 50px 10px 50px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.qr-screen-footer .equal-box {
  min-height: 110px;
}

.qr-screen-footer .bg-primary {
  background-color: #1d8ade59 !important;
}

.location-error {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000d9;
  z-index: 99;
  color: #fd8137;
  font-size: x-large;
}

.sml-blue-header.text-left thead th:last-child {
  text-align: left !important;
}

.global-search-box-cntr {
  top: 100%;
  position: absolute;
  right: 15px;
  margin-top: 15px;
  width: 99%;
  background: #fff;
  z-index: 3;
  padding: 10px 15px 20px 15px;
  border-radius: 4px;
  box-shadow: 0px -1px 11px 0;
  box-sizing: border-box;
  // display: none;
  margin-bottom: 15px;
}

.global-search-box-cntr:before {
  content: "";
  position: absolute;
  top: -9px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 11px solid #fff;
}

.global-search-box .form-control {
  font-size: 12px;
  height: 31px;
  color: #666;
  font-family: "Roboto-regular";
}

.global-search-box .form-control::placeholder {
  /*text-transform: uppercase;*/
  font-family: "Roboto-regular";
  font-size: 12px;
  letter-spacing: 0.5px;
}

.scheduled-date .heading {
  padding-top: 4px;
  margin-bottom: 4px;
  color: #666;
  font-family: "Roboto-regular";
  font-size: 15px;
}

.scheduled-date .form-control {
  width: 130px;
  // padding: 0.25rem 1.5rem 0.25rem 0.5rem;
  // background: url("img/calendar-icon.png") no-repeat 93%;
  background-size: 10%;
}

.table-layout-fixed {
  table-layout: fixed;
}

.dashed-border-bottom {
  border-bottom: 1px dashed #ddd;
}

.custom-upload-btn {
  background: #fff;
  border-radius: 8px;
  text-align: center;
  color: #2293e9;
  border: 1px solid #217dc3;
  font-size: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.custom-upload-btn .upload-input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.custom-upload-btn .upload-input+span {
  cursor: pointer;
}

.upload-excel-form-cntr {
  box-shadow: 0px 3px 6px #00000029;
  background: #f8f8ff;
}

.upload-excel-form-cntr input,
.upload-excel-form-cntr select {
  font-size: 12px;
}

.upload-excel-form-cntr input::placeholder {
  // color: rgb(197 197 197 / 70%);
  font-size: 12px;
  font-weight: normal;
  font-family: "Roboto-regular";
}

.select-process-box .custom-control {
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  background: #f8f8ff;
  padding: 0.5rem 1rem;
}

.select-process-box .custom-control input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
}

.select-process-box .custom-control label {
  margin-bottom: 0;
  vertical-align: middle;
  margin-left: 5px;
}

.add-outline-plus-btn-small {
  border: 3px solid #60c1f1;
  border-radius: 10px;
  width: 28px;
  height: 28px;
  text-align: center;
  padding: 0;
  line-height: 24px;
  margin-top: 2rem !important;
  font-size: 12px;
}

.upload-excel-form-listing-cntr .card-header>h5 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.upload-excel-form-listing-cntr .card-header>h5 button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-decoration: none;
  background: #ff0000;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  line-height: 18px;
  font-size: 10px;
}

.upload-excel-form-listing-cntr .card-header>h5 button.collapsed>i::before {
  content: "\f067";
}

.upload-excel-form-listing-cntr .candidate-record-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 10px);
}

.candidate-record-box .record {
  flex: 1;
  padding-right: 1rem;
  width: calc(100% / 4);
  overflow: hidden;
}

.candidate-record-box .record label {
  margin-bottom: 0;
  font-family: "Roboto-Condensed";
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
}

.candidate-record-box .record span {
  font-family: "Roboto-Condensed";
  font-size: 14px;
  font-weight: normal;
  color: #40aefd;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 73%;
  vertical-align: middle;
}

.candidate-record-box .record select {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font-size: 12px;
  height: 26px !important;
  padding: 3px 14px 3px 12px;
  max-width: 85%;
  color: #40aefd;
  border: 1px solid #40aefd;
}

.bg-blue-version2 {
  background-color: #e9f6ff !important;
}

.ltr-spc-0 {
  letter-spacing: normal !important;
}

@media only screen and (max-width: 480px) {
  .btn-custom {
    font-size: 0.7rem;
  }
}

.new-login-design {
  position: relative;
  width: 100%;
  height: 100%;
}

.new-login-design::before {
  content: "";
  background: url(./assets/icons/Path-left.png) no-repeat 0% 100%;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.new-login-design::after {
  content: "";
  background: url(./assets/icons/Path-right.png) no-repeat 100% 100%;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-size: contain;
}

.new-login-design .selfie-img {
  position: fixed;
  top: 20%;
  left: 11%;
}

.new-login-design .welcome-msg {
  color: #217dc3;
  font-family: Roboto-Condensed;
  letter-spacing: 0.06px;
  margin-bottom: 0;
  line-height: 36px;
}

.new-login-design .client-img {
  height: 36px;
  width: auto;
}

.login-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-30%, -50%);
  max-width: 500px;
  z-index: 2;
  background: #fff;
}

.overlap-login-cntr {
  position: relative;
  width: 100%;
  box-shadow: 0px 4px 33px #4ca0acb5;
  padding: 50px 70px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  min-height: 500px;
}

.overlap-login-cntr .input-group-text {
  border-right: 0;
  background-color: transparent;
}

.overlap-login-cntr .form-group label {
  font-family: Roboto-Condensed;
  font-size: 14px;
}

.overlap-login-cntr .form-control {
  border-left: 0;
  height: 44px;
  font-size: 0.875rem;
}

.overlap-login-cntr .form-control:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}

.overlap-login-cntr .form-control::placeholder {
  color: #999;
  font-family: "Roboto-regular";
  font-size: 12px;
  letter-spacing: 0.02px;
  font-weight: normal;
}

.overlap-login-cntr .login-btn {
  background-color: #60c1f1;
  border: 1px solid #60c1f1;
  border-radius: 5px;
  font-size: 12px;
  margin: 30px auto 20px auto;
  display: block;
  padding: 15px 40px;
  min-width: 150px;
}

.powered-by-box {
  float: left;
  width: 100%;
  text-align: center;
  padding: 15px;
}

.powered-by-box img {
  height: 40px;
  display: block;
  margin: 5px auto;
}

.forgot-pwd {
  text-decoration: none !important;
}

@font-face {
  font-family: OpenSans-SemiBold;
  src: url("./font/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans-Bold;
  src: url("./font/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans-Italic;
  src: url("./font/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans-Regular;
  src: url("./font/OpenSans-Regular.ttf") format("truetype");
}

// body {
//   font-family: "OpenSans-Regular";
// }
// .table {
//   border-collapse: collapse;
//   border-spacing: 0;
//   overflow:wrap;
// }
// .table, .table th, .table td {
//   border: 1px solid black;
// }
// .table th, .table td {
//   padding: 5px;
// }
h4 {
  color: #707070;
  text-transform: uppercase;
  font-family: OpenSans-Bold;
  font-size: 16px;
}

.white-table-rows td {
  padding: 10px 15px;
  font-size: 12px;
  color: #666;
  background-color: #fff;
  text-align: left;
}

.gray-table-rows td {
  padding: 10px 15px;
  font-size: 12px;
  color: #666;
  text-align: left;
  background-color: #f8f8f8 !important;
}

.green-table-rows td {
  padding: 10px 15px;
  font-size: 12px;
  color: #666;
  text-align: left;
  background-color: #ddf2e3;
}

.container-tbl td {
  /*padding: 0;*/
  font-size: 12px;
  color: #666;
  background-color: #fff;
}

.rounded-15 {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

.preview-container {
  width: 815px;
  background-color: #fbfbfb;
}

.download-btn-positioned {
  position: absolute;
  top: 15px;
  right: -150px;
}

.bg-ef {
  background-color: #efefef;
}

.bg-white {
  background-color: #fff;
}

.bg-f8 {
  background-color: #f8f8f8;
}

.txt-white {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .preview-container {
    width: 100%;
  }
}

//*admin//
.nav-tabs-plain.transparent {
  border-bottom: 0 !important;
}

.nav-tabs-plain.transparent .nav-link.active {
  background-color: transparent;
  color: #2680eb;
}

.btn-dark-blue-secondary {
  background-color: #fff;
  border: 1px solid #217dc3;
  color: #217dc3;
  text-align: center;
  padding: 8px 20px;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.btn-dark-blue-secondary:hover {
  opacity: 0.8;
}

.sml-blue-bg-header thead th {
  color: #fff;
  background-color: #2680eb;
  font-size: 0.6rem !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Roboto-bold;
  border: none;
  padding: 5px 6px;
}

.sml-blue-bg-header thead tr:first-child th:first-child {
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-top-left-radius: 4px;
}

.sml-blue-bg-header thead tr:first-child th:last-child {
  border-top-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-top-right-radius: 4px;
}

.sml-blue-bg-header tbody td {
  vertical-align: middle;
  font-size: 0.7rem !important;
  color: #666666;
  border-color: #efefef;
  padding: 0px 5px;
}

.gray-circle-btns.bg-trns-menu-blue>span {
  background-color: transparent;
}

.gray-circle-btns.bg-trns-menu-blue>span img {
  height: auto;
}

.gray-circle-btns.no-span span {
  width: unset;
  height: unset;
  padding: 0;
}

.t-d-none:hover {
  text-decoration: none;
}

.type {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  padding: 3px 10px;
}

.type.none {
  background-color: #929292;
  color: #fff;
}

.agent-select {
  background-color: #bdd9f9;
  color: #2680eb;
}

.auditor-select {
  background-color: #edddb0;
  color: #ebc55c;
}

.both-select {
  background-color: #b7edb9;
  color: #11c417;
}

.hover-ef-bg:hover {
  background-color: #efefef !important;
}

.add-exe-form {
  border: 1px solid #2680eb;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 25px;
  position: relative;
  margin-bottom: 15px;
}

.close-icon {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 5px;
  right: 5px;
  // background-image: url(../img/close-icon2.png);
  background-repeat: no-repeat;
  cursor: pointer;
}

.add-exe-form .heading {
  color: #2680eb;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: Roboto-BoldCondensed;
}

.add-exe-form select {
  font-size: 14px;
  height: 35px;
}

.add-exe-form label {
  color: #217dc3;
  font-size: 13px;
  font-family: Roboto-regular;
  margin-bottom: 0;
}

.add-exe-form .form-control {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border: 1px solid #217dc3;
  padding: 7px 5px !important;
  font-size: 12px;
}

.add-exe-form input[type="text"]::placeholder,
.add-exe-form textarea::placeholder {
  font-size: 12px;
  font-family: Roboto-italic;
  color: #217dc3;
  opacity: 0.4;
  letter-spacing: 0.5px;
  padding-left: 5px;
}

.add-exe-form .form-group {
  margin-bottom: 0.3rem;
}

.add-exe-form .primary-btn {
  background-color: #217dc3;
  text-transform: uppercase;
  color: #fff;
  height: 40px;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  font-family: Roboto-BoldCondensed;
  letter-spacing: 1px;
}

.add-exe-form .primary-btn:disabled {
  opacity: 0.57;
}

.tooltip {
  font-size: 11px;
}

.circle-bg-icons li svg {
  width: 18px;
}

.circle-bg-icons li.active path,
.circle-bg-icons li.active circle {
  fill: #fff;
}

.circle-bg-icons li.active a span:first-child {
  background-color: #2680eb;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  height: 30px;
  width: 30px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.header-2 .top-right {
  color: #2680eb;
}

.header-2 .top-right .clock-box {
  background-color: #ececec;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.bg-fb {
  background-color: #fbfbfb;
}

.txt-blue-2 {
  color: #2680eb;
}

.roboto-medium {
  font-family: Roboto-Medium;
}

.bg-f0 {
  background-color: #f0f5fc;
}

.bg-fc {
  background-color: #fcfcfc;
}

.blue-chart-circle {
  background-color: #217dc3;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  display: inline-block;
}

.green-chart-circle {
  background-color: #11c417;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  display: inline-block;
}

.red-chart-circle {
  background-color: #fb175d;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  display: inline-block;
}

.border-bottom-dashed-gray {
  border-bottom: 1px dashed #dee2e6;
}

.change-password-form label {
  color: #217dc3;
  text-transform: uppercase;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-family: Roboto-bold;
}

.change-password-form .form-control {
  border-color: #217dc3;
}

.change-password-form .input-group-prepend>span {
  border-color: #217dc3;
}

.change-password-form .primary-btn {
  background-color: #2580eb;
  border-color: #4b78af;
  text-transform: uppercase;
  font-size: 0.6rem;
  color: #fff;
  font-family: Roboto-bold;
  letter-spacing: 1px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.change-password-form .primary-btn:hover {
  opacity: 0.9;
}

.roboto-italic {
  font-family: Roboto-italic;
}

.change-password-form .otp-box-2 input {
  background: #ffffff;
  border: 1px solid #217dc3;
  float: left;
  margin: 3px;
  padding: 0.25rem 0.5rem;
}

.theme-2.big-nav-tabs-2 .nav-link {
  width: 120px;
  font-size: 0.7rem;
  font-family: Roboto-BoldCondensed;
  border-bottom: 3px solid transparent !important;
  height: auto;
  padding: 0;
  display: inherit;
}

.theme-2.big-nav-tabs-2 .nav-link>span {
  border-radius: unset;
  margin-bottom: 1rem !important;
}

.theme-2.big-nav-tabs-2 .nav-item.active .nav-link.active {
  border-bottom: 3px solid #2680eb !important;
}

.theme-2.big-nav-tabs-2 .nav-item .nav-link>span {
  box-shadow: unset !important;
  height: 50px;
  border: unset;
  color: rgba(255, 255, 255, 0.6) !important;
  opacity: 0.6;
}

.theme-2.big-nav-tabs-2 .nav-item:nth-child(2) .nav-link>span {
  background-color: #5326eb;
}

.theme-2.big-nav-tabs-2 .nav-item:nth-child(3) .nav-link>span {
  background-color: #b126eb;
}

.theme-2.big-nav-tabs-2 .nav-item:nth-child(4) .nav-link>span {
  background-color: #eb26b6;
}

.theme-2.big-nav-tabs-2 .nav-item:nth-child(2) .nav-link.active {
  border-bottom: 3px solid #5326eb !important;
}

.theme-2.big-nav-tabs-2 .nav-item:nth-child(3) .nav-link.active {
  border-bottom: 3px solid #b126eb !important;
}

.theme-2.big-nav-tabs-2 .nav-item:nth-child(4) .nav-link.active {
  border-bottom: 3px solid #eb26b6 !important;
}

.theme-2.big-nav-tabs-2 .nav-item .nav-link.active>span {
  border: unset;
  color: rgba(255, 255, 255, 9) !important;
  opacity: 9;
}

.sml-blue-bg-header.bg-sky-blue thead th {
  background-color: #72b3ff;
}

.roboto-regular {
  font-family: Roboto-regular;
}

.all-head-center tr th,
.all-body-center tr td {
  text-align: center;
}

.pt-7-pb-7-td td {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.has-close-icon {
  position: relative;
}

.has-close-icon>.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.info-common {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  padding: 5px 5px;
  text-align: center;
  font-size: 0.65rem;
}

.info-red {
  color: #fb175d;
  background-color: #fed5e2;
}

.info-gray {
  color: #666666;
  background-color: #d6d6d6;
}

.info-yellow {
  color: #e9b31d;
  background-color: #fbf1d6;
}

.info-blue {
  color: #217dc3;
  background-color: #d7e8f4;
}

.info-green {
  color: #11c417;
  background-color: #b7edb9;
}

.info-red.secondary {
  border: 1px solid #fb175d;
  color: #fb175d;
  background-color: #fff;
}

.info-yellow.secondary {
  border: 1px solid #e9b31d;
  color: #e9b31d;
  background-color: #fff;
}

.txt-red {
  color: #fb175d;
}

.red-astric {
  color: #fb175d;
  vertical-align: top;
}

.txt-blue-26 {
  color: #2680eb;
}

.notification-sml-circle-yellow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f8a808;
  width: 10px;
  height: 10px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
}

.dropdown-toggle .notification-sml-circle-yellow {
  position: absolute;
  top: 25px;
  right: 5px;
}

.aadhaarVerificationCardDetails {
  background-color: #2fa5df;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.dashboard-client-image {
  max-width: 200px;
  min-width: 200px;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 1px;
}

.capitalize {
  text-transform: capitalize;
}

.word-break-all {
  word-break: break-all;
}

.unique-code {
  position: fixed;
  z-index: 32;
  background: white;
  top: 56px;
  font-size: 17px;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
}

.footer {
  height: 30px;
  background-color: #fff;
  box-shadow: 2px 0px 1px 2px #00000029;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font: normal normal normal 25px/38px Poppins;
  letter-spacing: 3.75px;
  position: fixed;
  color: #0071a9;
  width: 100%;
  bottom: 0;
  z-index: 5;
  font-size: 14px;
  align-items: center;

  span {
    color: #000;
    letter-spacing: .5px !important;
  }

  .footer-power {
    margin-right: 3rem;
  }

}

.footer a {
  text-decoration: none;
}

@media screen and (max-width:600px) {
  .footer {
    font-size: 10px;
    letter-spacing: 0px;
    height: 14px;

    .footer-power {
      margin-right: 0rem;

      img {
        height: 9px;
      }
    }
  }
}

.d-inline-block {
  display: inline-block;
}

.btn-block {
  display: block;
  width: 100%;
}

.dropleft .dropdown-menu {
  right: 100%;
  top: 0;
  margin-top: 0;
  margin-right: .125rem;
  left: auto;
}
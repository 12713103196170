.aadhaarMainContainer {
  width: 460px;
  max-width: 100%;
  display: flex;
  margin: auto;
}

.aadhaarSubContainer {
  width: 100%;
  /* padding-right: 15px; */
  padding: 0 15px;
  /* margin-right: auto; */
  margin-top: 1.5em;
}

.aadhaar-row {
  display: flex;
  text-align: left;
  justify-content: space-between;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.aadhaarHeaderText {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #5eb9ff;
}

.aadhaarSubHeaderText {
  font-family: inherit;
  font-weight: 500;
  margin-top: 1rem !important;
  font-size: 1.25rem;
  margin-bottom: 1rem !important;
  letter-spacing: 1px;
  color: #666666;
  line-height: 1.2;
}

.aadhaarOTPContainer {
  margin-top: 50px;
}

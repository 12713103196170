.policy-left-section {
    font-family: 'poppins-Medium';
    padding-top: 20px;

    img {
        width: 100%;
    }

    iframe {
        width: 100%;
        height: fit-content;
        min-height: 350px;
    }
}

.policy-right-section {
    padding-top: 20px;
    .screen-shot{
        width: 100%;
    }
}

.camera-item {
    border: 2px solid lightblue;
    width: 90%;
    margin-left: 5%;
}

.isrecording {
    border: 2px solid red; /* Set your border color */
    border-radius: 8px; /* Adjust the border-radius as needed */
    position: relative;
  }

  .isrecording::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid transparent;
    border-radius: 8px;
    box-shadow: 0 0 10px 2px red; /* Set your glow color */
    opacity: 0;
    animation: glow 2s infinite; /* Adjust the animation duration as needed */
  }

  @keyframes glow {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.2;
    }
  }

.camera-btn {
    background: white;
    border-radius: 5px;

    &:hover {
        box-shadow: 0 0 5pt 0.5pt #D3D3D3;
        scale: 1.1;
    }

    .record {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        background: red;
    }
}
.info-container {
  color: #000;
  background-color: #efefef;
  height: 100%;
  background: #e8e8e8 0% 0% no-repeat padding-box;
  border: 2px solid #d4d4d4;
  border-radius: 9px;
}
.label-value-container {
  display: flex;
  padding: 10px;
}



.label {
  flex: 1;
  font-weight: bold;
}

.label-value {
  flex: 2;
}

.info-subContainer {
  height: 100%;
  width: 100%;
  /* border: 2px solid rgb(212, 212, 212); */
  background: #e8e8e8 0% 0% no-repeat padding-box;
  /* border: 2px solid #D4D4D4; */
  border-radius: 9px;
  overflow: hidden;
}

.info-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #2fa5df;
  color: #fff;
}

.info-button {
  background: #707070;
  visibility: hidden;
}

.documents-verified {
  margin: 20px;
}

.documents-verified-heading {
  color: #0071a9;
}

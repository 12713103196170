.ACMainContainer {
  width: 460px;
  max-width: 100%;
  display: flex;
  margin: auto;
}

.ACSubContainer {
  width: 100%;
  padding: 0 15px;
  margin-top: 1.5em;
}

.ACRow {
  display: flex;
  text-align: left;
  justify-content: space-between;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.ACHeaderText {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #5eb9ff;
}

.ACConfirmationContainer {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.ACConfirmationBox {
  box-shadow: 0px 1px 2px #00000029;
  border: 1px dashed #11ac20;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  height: 80px;
  display: flex;
  margin-top: 1em;
  padding: 5px 15px;
  align-items: center;
}

.ACConfirmDiv {
  width: 80%;
  display: flex;
  align-items: center;
}

.ACConfirmText {
  margin-left: 1rem !important;
  letter-spacing: 0.5px;
  color: #5eb9ff;
  font-size: 1.2rem;
}

.ACCheckBox {
  width: 20%;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  float: left !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  display: flex;
  justify-content: flex-end !important;
}

.ACSuccessText {
  margin-top: 1.5rem !important;
  margin-left: 1rem !important;
  display: flex;
  align-items: center;
  color: #21a947;
  font-size: 0.9rem;
}

.hardware {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .hardware-test {
      height: 300px;
      width: 430px;
    }
    // .kHmnKJ {
    //   background-color: transparent;
    // }
    // .dTwlzX {
    //   background: #5eb9ff;
    //   color: white;
    //   font-weight: 100;
    // }
    // .dTwlzX:hover {
    //   background: #5eb9ff;
    //   opacity: 0.4;
    //   color: #fff;
    // }
    .video-recorder__Wrapper-sc-7k20rv-0 {
      background-color: transparent;
      display: flex;
    }
  
    .button__Button-hkteey-0 {
      visibility: hidden;
    }
  
    .button__Button-hkteey-0::after {
      content: "Turn On Camera";
      visibility: visible;
      display: block;
      // position: absolute;
      background: #5eb9ff;
      color: white;
      padding: 10px 5px;
      font-weight: 100;
      border-radius: 5px;
      // margin-bottom: 100px;
      justify-content: center;
      align-items: center;
    }
  
    .button__Button-hkteey-0:hover {
      background: #5eb9ff;
      opacity: 0.6;
      color: #fff;
    }
  }
  .left-button {
    margin-right: 10px;
    width: 100px;
  }
  .right-button {
    margin-left: 10px;
    width: 100px;
    background-color: red;
    color: #fff;
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
  .right-button:hover {
    opacity: 0.5;
    color: #fff;
  }
.instructionBox {
  padding: 1.5rem !important;
  margin-bottom: 0.5rem !important;
  width: 100%;
  border-radius: 0.25rem !important;
  background: #e9e9e930 0% 0% no-repeat padding-box;
  border: 2px dashed #5eb9ff !important;
}

.containerHeight {
  height: calc(100% - 50px);
}

.numberIcon {
  width: 30px !important;
  height: 30px !important;
  background: #5eb9ff 0% 0% no-repeat padding-box !important;
  font-weight: 600;
  margin: 5px;
}

.cameraImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kycButton {
  text-transform: none !important;
  font: normal normal 300 15px/22px Poppins !important;
  letter-spacing: 1.8px !important;
  width: 45%;
}

.mrB {
  margin-bottom: 1em;
}

.mrT {
  margin-top: 1em;
}

.infoTextContainer {
  display: flex;
  flex-direction: column;
  margin: 2em 0;
  align-items: center;
}

.kycInfoTxt {
  margin: 0;
  font: normal normal normal 13px/15px Poppins;
  letter-spacing: 1.2px;
  color: #818181;
}

.kycHighlightedTxt {
  text-align: center;
  font: normal normal bold 13px/20px Poppins;
  letter-spacing: 1.2px;
  color: #5eb9ff;
  margin: 0;
}

#kyc-modalContainer {
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #707070;
  opacity: 0.89;
  width: 500px;
  border-radius: 29px;
}

.kyc-modalText {
  text-align: center;
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}

.kyc-modalBackground {
  background: "#4ED960";
  color: "white";
}

.kyc-modalColor {
  color: #4ed960;
}

#kyc-successIcon {
  height: 100px;
  width: 100px;
}

.mainContainer {
  width: 500px;
  max-width: 100%;
  display: flex;
  margin: auto;
}
.subContainer {
  width: 100%;
  /* padding-right: 15px; */
  padding: 0 15px;
  /* margin-right: auto; */
  margin-top: 1.5em;
}

.varified {
  border: 3px solid #5eb9ff !important;
}

.row {
  // display: flex;
  flex-wrap: wrap;
  text-align: left;
  // justify-content: space-between;
  // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.right {
  justify-content: flex-end !important;
}

.headerTxt {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #5eb9ff;
}

.subHeaderTxt {
  font-family: inherit;
  font-weight: 500;
  margin-top: 1rem !important;
  font-size: 1.25rem;
  margin-bottom: 1rem !important;
  letter-spacing: 1px;
  color: #666666;
  line-height: 1.2;
}

.clockContainer {
  margin-top: 1.5rem !important;
  align-items: flex-end;
  display: flex;
}

.infoTxt {
  color: #666666;
  margin-left: 8px;
  letter-spacing: 1px;
}

.uploadContainer {
  max-width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.upload {
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #c5c5c5;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  height: 50px;
  display: flex;
  margin-top: 1em;
  padding: 5px 15px;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.uploadDiv {
  width: 80%;
  display: flex;
  align-items: center;
}

.uploadTxt {
  margin-left: 1rem !important;
  letter-spacing: 0.5px;
  color: #666666;
  font-size: 0.7rem;
}

.checkBox {
  width: 20%;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  float: left !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  display: flex;
  justify-content: flex-end !important;
}

.loader-container {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #272a2dc7;
}
.loader-container-full {
  height: 100%;
  top:0px;
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #272a2dc7;
}

.customer-consent-container {
  min-height: 100%;
  .banner {
    background-color: #5eb9ff;
    height: 60px;
  }
  .consent-header {
    color: #5eb9ff;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: left;
    letter-spacing: 1px;
    font-weight: 500;
    margin-left: 20px;
  }

  .consent-text-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 20px;
    margin: 20px;
    border: 1px dashed #5eb9ff;
    background-color: #ffffff;
    @media only screen and (max-width: 600px) {
      padding: 5px;
    }

    .content {
      margin: 20px;
      border: 1px solid #ddd;
      border-style: groove;
      background-color: #ffffff;
      padding: 20px;

      @media only screen and (max-width: 600px) {
        padding: 10px;
      }

      .consent-text {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        color: #666666;
      }

      .consent-term-heading {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: left;
        color: #5eb9ff;
      }
    }

    // .grant-container {
    //   display: flex;
    //   align-items: center;
    //   .grant-text {
    //     font-size: 0.7rem;
    //     font-weight: 400;
    //     line-height: 1.5;
    //     color: #666666;
    //   }

    //   .checkbox-round {
    //     width: 1.3em;
    //     height: 1.3em;
    //     background-color: white;
    //     border-radius: 50%;
    //     vertical-align: middle;
    //     border: 1px solid #ddd;
    //     -webkit-appearance: none;
    //     outline: none;
    //     cursor: pointer;
    //     padding: 10px;
    //     margin-right: 10px;
    //     margin-left: 20px;
    //   }
    // }

    .proceed-button {
      background: #5eb9ff;
      color: white;
      width: 200px;
      float: left;
      margin: 20px;
    }
  }
}

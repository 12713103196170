.customPickerWrapper {
  
    .input-container {
        position: relative;
    }
}

.customPickerModal {
    width: 100%;
    height: 100%;
    display: block;

    .picker-footer {
        background-color: #fff;
        width: 100%;
        width: 300px;
        margin: auto;
    }
}

.customTimePicker {
    height: auto;
    padding: 20px;
    width: 300px;
    margin: auto;
    margin-top: 10%;
    display: block;
    background-color: #27abec;

    .time-container{
        display: flex;
        justify-content: center;
        align-items: center;
        .hours{
            z-index: 10;
            .timeList{
                position: absolute;
                background-color: #fff;
                margin-left: 30px;
                margin-top: 5px;
                max-height: 300px;
                overflow: auto;
                display: none;
                ul{
                    list-style-type: none;
                    padding: 0px;
                    li{ padding: 5px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        &:hover{
                            background-color: lightgray;
                        }
                    }
                    .active{
                        background-color: #27abec;
                    }
                }
            }
            .show{
                display: block;
            }
        }
        
    }

    input {
        font-weight: 400;
        line-height: 1.235;
        font-size: 2.75rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        max-width: 100px;
    }

    .btn {
        cursor: pointer;
    }

}